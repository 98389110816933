import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { camelCase } from 'change-case';
import '../PabloStyles/SelectionWindowComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faImages } from '@fortawesome/pro-solid-svg-icons';
import { PabloConstants } from '../PabloConstants';
import { determineSelectionWindowIconParams } from '../PabloUtil';

const SelectionWindowComponent = props => {
  const { selectedColor, selectedStickerImage, selectedStickerImagePath, toolbarSelection } = props;
  const colorWhite = PabloConstants.ENABLED_COLORS[PabloConstants.ENABLED_COLORS.length - 1].hex;

  let selectionWindowIconParams = {};

  if (
    toolbarSelection === PabloConstants.TOOL.FILL ||
    toolbarSelection === PabloConstants.TOOL.PENCIL ||
    toolbarSelection === PabloConstants.TOOL.SPRAY ||
    toolbarSelection === PabloConstants.TOOL.MARKER
  ) {
    selectionWindowIconParams = determineSelectionWindowIconParams(toolbarSelection);
  }

  return (
    <div className="SelectionWindowComponent">
      <div
        className="brushSelectionWindow"
        style={{
          backgroundColor:
            selectedColor === colorWhite &&
            (toolbarSelection === PabloConstants.TOOL.FILL ||
              toolbarSelection === PabloConstants.TOOL.PENCIL ||
              toolbarSelection === PabloConstants.TOOL.SPRAY ||
              toolbarSelection === PabloConstants.TOOL.MARKER)
              ? '#DB8500'
              : colorWhite,
        }}
      >
        {(toolbarSelection === PabloConstants.TOOL.FILL ||
          toolbarSelection === PabloConstants.TOOL.PENCIL ||
          toolbarSelection === PabloConstants.TOOL.SPRAY ||
          toolbarSelection === PabloConstants.TOOL.MARKER) && (
          <div>
            <FontAwesomeIcon
              className={`selectionWindowIcon ${selectionWindowIconParams.className}`}
              icon={selectionWindowIconParams.icon}
              style={{
                color: selectedColor,
              }}
              aria-hidden="true"
            />
            <span className="sr-only">
              <Translate
                id={`pablo-sr-${PabloConstants.ENABLED_COLORS.filter(enabledColor => {
                  return enabledColor.hex === selectedColor;
                }).reduce((localizedAltString, enabledColor) => {
                  return localizedAltString + enabledColor.name;
                }, '')}-selected`}
              />
            </span>
          </div>
        )}
        {toolbarSelection === PabloConstants.TOOL.STICKER && (
          <div>
            <img
              className="stickerSelection"
              src={selectedStickerImagePath}
              // eslint-disable-next-line react/destructuring-assignment
              alt={props[`${camelCase(selectedStickerImage.replace('.svg', ''))}SelectedAlt`]}
            />
            <span className="sr-only">
              <Translate id={`pablo-sr-${selectedStickerImage.replace('.svg', '')}-selected`} />
            </span>
          </div>
        )}
        {toolbarSelection === PabloConstants.TOOL.ERASER && (
          <div>
            <FontAwesomeIcon className="eraserSelection" icon={faEraser} />
            <span className="sr-only">
              <Translate id="pablo-sr-eraser-selected" />
            </span>
          </div>
        )}
        {toolbarSelection === PabloConstants.TOOL.SET_BACKGROUND && (
          <div>
            <FontAwesomeIcon className="backgroundSelection" icon={faImages} />
            <span className="sr-only">
              <Translate id="pablo-sr-set-background-selected" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SelectionWindowComponent.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  toolbarSelection: PropTypes.string.isRequired,
  selectedStickerImage: PropTypes.string.isRequired,
  selectedStickerImagePath: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  hotAirBalloonSelectedAlt: state.languageWidget.translation['pablo-alt-hot-air-balloon-selected'],
  sunSelectedAlt: state.languageWidget.translation['pablo-alt-sun-selected'],
  carSelectedAlt: state.languageWidget.translation['pablo-alt-car-selected'],
  birdSelectedAlt: state.languageWidget.translation['pablo-alt-bird-selected'],
  starSelectedAlt: state.languageWidget.translation['pablo-alt-star-selected'],
  butterflySelectedAlt: state.languageWidget.translation['pablo-alt-butterfly-selected'],
  flowerSelectedAlt: state.languageWidget.translation['pablo-alt-flower-selected'],
  cloudsSelectedAlt: state.languageWidget.translation['pablo-alt-clouds-selected'],
  moonAndStarsSelectedAlt: state.languageWidget.translation['pablo-alt-moon-and-stars-selected'],
  treeSelectedAlt: state.languageWidget.translation['pablo-alt-tree-selected'],
});

export default connect(mapStateToProps)(SelectionWindowComponent);
