import React from 'react';
import GamesPageRoutes from './GamesPage.routes';
import BrowseTemplate from '../Templates/Browse/BrowseTemplate';
import './Games.scss';

// TODO: delete this. Move JSX to container
const GamesPageContainer = () => {
  return (
    <BrowseTemplate>
      <GamesPageRoutes />
    </BrowseTemplate>
  );
};

export default GamesPageContainer;
