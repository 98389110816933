import { faCalculatorAlt, faBookReader, faStar, faBookAlt } from '@fortawesome/pro-solid-svg-icons';
// TODO: ADD BACK IN ONCE LOCAL CONTENT IS DONE
// import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { getLanguageData } from './Utils/localeUtilities';

const languageJson = getLanguageData();

export const WebStats = {
  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
  HOSTNAME_OVERRIDE: process.env.REACT_APP_HOSTNAME_OVERRIDE,
  HOST: process.env.REACT_APP_OVERRIDE_DOMAIN,
};

export const OverrideDomains = {
  // NEED TO UPDATE WITH NEW MARKET
  PROD: {
    US: 'school.eb.com',
    GB: 'school.eb.co.uk',
    AU: 'school.eb.com.au',
    IN: 'school.ebonline.in',
    NZ: 'school.ebonline.co.nz',
    ASIA: 'school.ebonline.com',
    BR: 'escola.britannica.com.br',
    BKIDS: { US: 'kids.britannica.com' },
  },
  QA: {
    US: 'school.qa.eb.com',
    GB: 'school.qa.eb.co.uk',
    AU: 'school.qa.eb.com.au',
    IN: 'school.qa.ebonline.in',
    NZ: 'school.qa.ebonline.co.nz',
    ASIA: 'school.qa.ebonline.com',
    BR: 'escola.qa.britannica.com.br',
    BKIDS: { US: 'kids.qa.britannica.com' },
  },
  DEV: {
    US: 'school.dev.eb.com',
    GB: 'school.dev.eb.co.uk',
    AU: 'school.dev.eb.com.au',
    IN: 'school.dev.ebonline.in',
    NZ: 'school.dev.ebonline.co.nz',
    ASIA: 'school.dev.ebonline.com',
    BR: 'escola.dev.britannica.com.br',
    BKIDS: { US: 'kids.dev.britannica.com' },
  },
};

export const InfantilAccountIds = {
  PROD: '9661986',
  STAGING: '9036842',
  QA: '2027394',
  DEV: '763590',
};

export const HostnameOverrides = {
  PROD: 'webstats.eb.com',
  QA: 'webstats.qa.eb.com',
  DEV: 'webstats.dev.eb.com',
};

export const RoutePath = {
  GAMES: languageJson['routes-games'],
  HOME: languageJson['routes-home'],
  MERCATOR: languageJson['routes-mercator'],
  PABLO: languageJson['routes-pablo'],
  READ: languageJson['routes-read'],
  BROWSE_PLAY: `${languageJson['routes-games']}/${languageJson['routes-browse']}`,
  BROWSE_READ: `${languageJson['routes-read']}/${languageJson['routes-browse']}`,
};

export const RouteHelpers = {
  BROWSE: languageJson['routes-browse'],
  PLAY: languageJson['routes-special-games'],
  READ: languageJson['routes-special-read'],
};

export const Endpoint = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API,
  CDN_URL: process.env.REACT_APP_CDN_URL,
  CDN_MITR_URL: process.env.REACT_APP_MITR_CDN_URL,
};

export const Env = {
  ENVIRONMENT: process.env.REACT_APP_ENV,
  VERSION: process.env.REACT_APP_VERSION,
};

export const Environments = {
  DEV: 'dev',
  QA: 'qa',
  PRODUCTION: 'prod',
};

export const Locales = {
  // NEED TO UPDATE WITH NEW MARKET
  PORTUGUESE_BRAZIL: 'pt-BR',
  ENGLISH_US: 'en-US',
  ENGLISH_UNITED_KINGDOM: 'en-GB',
  ENGLISH_AUSTRALIA: 'en-AU',
  ENGLISH_NEW_ZEALAND: 'en-NZ',
  ENGLISH_INDIA: 'en-IN',
  ENGLISH_ASIA: 'en-AS',
};

export const Backgrounds = {
  HOME: { CLASS: 'home-background' },
  CREATE: { CLASS: 'create-background' },
  READ: { CLASS: 'read-background' },
  READ_SOLO: { CLASS: 'read-background-solo' },
  READ_BROWSE: { CLASS: 'read-background-browse' },
  EXPLORE: { CLASS: 'explore-background' },
  PLAY: { CLASS: 'play-background' },
  PLAY_SOLO: { CLASS: 'play-background-solo' },
  PLAY_BROWSE: { CLASS: 'play-background-browse' },
  VAMOSFAZERARTE: { CLASS: 'create-background' },
  VAMOSLER: { CLASS: 'read-background' },
  VAMOSLER_SEÇÃO: { CLASS: 'read-background-browse' },
  VAMOSEXPLORAR: { CLASS: 'explore-background' },
  VAMOSJOGAR: { CLASS: 'play-background' },
  VAMOSJOGAR_SEÇÃO: { CLASS: 'play-background-browse' },
  ERROR: { CLASS: 'error-background' },
};

export const HomePageNav = [
  {
    animationStart: 'bounceIn',
    linkHref: `${languageJson['routes-read']}/${languageJson['routes-browse']}`,
    imgSrc: languageJson['shell-name-image-read'],
    imgAltText: languageJson['shell-name-alt-read'],
    label: languageJson['shell-name-read'],
    btnLabel: languageJson['shell-name-read'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: languageJson['routes-mercator'],
    imgSrc: languageJson['shell-name-image-explore'],
    imgAltText: languageJson['shell-name-alt-explore'],
    label: languageJson['shell-name-explore'],
    btnLabel: languageJson['shell-name-explore'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: `${languageJson['routes-games']}/${languageJson['routes-browse']}`,
    imgSrc: languageJson['shell-name-image-play'],
    imgAltText: languageJson['shell-name-alt-play'],
    label: languageJson['shell-name-play'],
    btnLabel: 'Please ensure that the audio button is turned off. ' + languageJson['shell-name-play'],
  },
  {
    animationStart: 'bounceIn',
    linkHref: languageJson['routes-pablo'],
    imgSrc: languageJson['shell-name-image-create'],
    imgAltText: languageJson['shell-name-alt-create'],
    label: languageJson['shell-name-create'],
    btnLabel: languageJson['shell-name-create'],
  },
];

export const Grades = [
  {
    gradesId: 0,
    name: 'Kindergarten',
  },
  {
    gradesId: 1,
    name: 'First Grade',
  },
  {
    gradesId: 2,
    name: 'Second Grade',
  },
  {
    gradesId: 3,
    name: 'Pre-K',
  },
];

export const GradesRange = [
  // NEED TO UPDATE WITH NEW MARKET
  {
    gradesRangeId: 0,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 0,
  },
  {
    gradesRangeId: 1,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 1,
  },
  {
    gradesRangeId: 2,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 2,
  },
  {
    gradesRangeId: 3,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 3,
  },
  {
    gradesRangeId: 4,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 4,
  },
  {
    gradesRangeId: 5,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 5,
  },
  {
    gradesRangeId: 6,
    gradesId: [0, 1, 2, 3],
    name: 'Pre-K - 2',
    market: 6,
  },
];

// Category Ids must be unique!
// For a particular market the math category must come first in order
// for the browse page to display the correct content
export const Categories = [
  // NEED TO UPDATE WITH NEW MARKET
  // 0 - Play, en-US
  {
    categoriesId: 0,
    categoriesName: 'Math',
    marketsId: 0,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 1 - Play, en-US
  {
    categoriesId: 1,
    categoriesName: 'Language Arts',
    marketsId: 0,
    bucketId: 0,
    icon: faBookReader,
  },
  // 2 - Play, pt-BR
  {
    categoriesId: 2,
    categoriesName: 'Matemática',
    marketsId: 1,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 3 - Read, en-US
  {
    categoriesId: 3,
    categoriesName: 'Read',
    marketsId: 0,
    bucketId: 1,
    icon: faStar,
  },
  // {
  //   categoriesId: 4,
  //   categoriesName: 'Science',
  //   marketsId: 0,
  //   bucketId: 0,
  //   icon: faFlask,
  // },
  // 5 - Read, pt-BR
  {
    categoriesId: 5,
    categoriesName: 'Livros',
    marketsId: 1,
    bucketId: 1,
    icon: faBookAlt,
  },
  // 6 - Play, pt-BR
  {
    categoriesId: 6,
    categoriesName: 'Língua portuguesa',
    marketsId: 1,
    bucketId: 0,
    icon: faBookReader,
  },
  // 7 - Read, pt-BR
  // TODO: ADD BACK IN ONCE LOCAL CONTENT IS DONE
  // {
  //   categoriesId: 7,
  //   categoriesName: 'Diversão',
  //   marketsId: 1,
  //   bucketId: 1,
  //   icon: faSmile,
  // },
  // 8 - Play, en-GB
  {
    categoriesId: 8,
    categoriesName: 'Math',
    marketsId: 2,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 9 - Play, en-GB
  {
    categoriesId: 9,
    categoriesName: 'Language Arts',
    marketsId: 2,
    bucketId: 0,
    icon: faBookReader,
  },
  // 10 - Play, en-AU
  {
    categoriesId: 10,
    categoriesName: 'Math',
    marketsId: 3,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 11 - Play, en-AU
  {
    categoriesId: 11,
    categoriesName: 'Language Arts',
    marketsId: 3,
    bucketId: 0,
    icon: faBookReader,
  },
  // 12 - Read, en-GB
  {
    categoriesId: 12,
    categoriesName: 'Read',
    marketsId: 2,
    bucketId: 1,
    icon: faStar,
  },
  // 13 - Read, en-AU
  {
    categoriesId: 13,
    categoriesName: 'Read',
    marketsId: 3,
    bucketId: 1,
    icon: faStar,
  },
  // 14 - Play, en-NZ
  {
    categoriesId: 14,
    categoriesName: 'Math',
    marketsId: 4,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 15 - Play, en-NZ
  {
    categoriesId: 15,
    categoriesName: 'Language Arts',
    marketsId: 4,
    bucketId: 0,
    icon: faBookReader,
  },
  // 16 - Read, en-NZ
  {
    categoriesId: 16,
    categoriesName: 'Read',
    marketsId: 4,
    bucketId: 1,
    icon: faStar,
  },
  // 17 - Play, en-IN
  {
    categoriesId: 17,
    categoriesName: 'Math',
    marketsId: 5,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 18 - Play, en-IN
  {
    categoriesId: 18,
    categoriesName: 'Language Arts',
    marketsId: 5,
    bucketId: 0,
    icon: faBookReader,
  },
  // 19 - Read, en-IN
  {
    categoriesId: 19,
    categoriesName: 'Read',
    marketsId: 5,
    bucketId: 1,
    icon: faStar,
  },
  // 20 - Play, en-AS, Asia
  {
    categoriesId: 20,
    categoriesName: 'Math',
    marketsId: 6,
    bucketId: 0,
    icon: faCalculatorAlt,
  },
  // 21 - Play, en-AS, Asia
  {
    categoriesId: 21,
    categoriesName: 'Language Arts',
    marketsId: 6,
    bucketId: 0,
    icon: faBookReader,
  },
  // 22 - Read, en-AS, Asia
  {
    categoriesId: 22,
    categoriesName: 'Read',
    marketsId: 6,
    bucketId: 1,
    icon: faStar,
  },
];

export const Markets = [
  // NEED TO UPDATE WITH NEW MARKET
  {
    marketsId: 0,
    marketsCountry: 'US',
    marketsFullName: 'United States',
    marketsLanguage: 'en-US',
  },
  {
    marketsId: 1,
    marketsCountry: 'BR',
    marketsFullName: 'Brazil',
    marketsLanguage: 'pt-BR',
  },
  {
    marketsId: 2,
    marketsCountry: 'GB',
    marketsFullName: 'United Kingdom',
    marketsLanguage: 'en-GB',
  },
  {
    marketsId: 3,
    marketsCountry: 'AU',
    marketsFullName: 'Australia',
    marketsLanguage: 'en-AU',
  },
  {
    marketsId: 4,
    marketsCountry: 'NZ',
    marketsFullName: 'New Zealand',
    marketsLanguage: 'en-NZ',
  },
  {
    marketsId: 5,
    marketsCountry: 'IN',
    marketsFullName: 'India',
    marketsLanguage: 'en-IN',
  },
  {
    marketsId: 6,
    marketsCountry: 'ASIA',
    marketsFullName: 'Asia',
    marketsLanguage: 'en-AS',
  },
];

export const Buckets = [
  {
    bucketId: 0,
    bucketName: 'Play',
  },
  {
    bucketId: 1,
    bucketName: 'Read',
  },
];
