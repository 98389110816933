import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './App.scss';
import { withLocalize } from 'react-localize-redux';
import { Router } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import RouteChange from './Components/RouteChange/RouteChange';
import { backGroundChange } from './Utils/uriUtilities';
import history from './history';
import br from './i18n/localizations/pt-BR.json';
import en from './i18n/localizations/en-US.json';
import AppRoutes from './App.routes';
import { getLocationCode } from './Utils/localeUtilities';
import AnalyticsApi from './Services/AnalyticsApi';
import { getCookieByName } from './Utils/appUtilities';
import { WebStats, Locales, InfantilAccountIds, HostnameOverrides, OverrideDomains } from './constants';
import { setAccountId, setHostnameOverride, setOverrideDomain } from './Redux/actions/statsActions';
import { getEnvironmentFromPathname, getHostnameOverride, getOverrideDomain } from './Utils/statsUtilities';

const brazil = {
  languages: [{ name: 'Portuguese', code: 'pt-BR' }],
  translation: br,
  options: { renderToStaticMarkup },
};

const english = {
  languages: [{ name: 'English', code: 'en-US' }],
  translation: en,
  options: { renderToStaticMarkup },
};

class App extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-shadow
    const { initialize, setAccountId, setHostnameOverride, setOverrideDomain, startLanguage } = this.props;
    const { href } = window.location;
    const code = getLocationCode();

    switch (code) {
      case 'en-US':
        initialize(english);
        break;
      case 'pt-BR':
        initialize(brazil);
        break;
      case 'en-AU':
        initialize(english);
        break;
      case 'en-GB':
        initialize(english);
        break;
      default:
        initialize(english);
        break;
    }

    document.addEventListener('audio-change', () => {
      console.log('my awesome callback');
    });

    // Get account ID from cookie
    const accountId = getCookieByName(document.cookie, 'accountid');

    // Set of RegEx expressions to cover various environments and markets
    // NEED TO UPDATE WITH NEW MARKET
    const k2DevRegEx = /(k2).dev((.eb.co.uk)|(.eb.com.au)|(.ebonline.in)|(.ebonline.co.nz)|(.ebonline.com)|(.eb.com))/;
    const k2LocalRegEx = /(k2).local((.eb.co.uk)|(.eb.com.au)|(.ebonline.in)|(.ebonline.co.nz)|(.ebonline.com)|(.eb.com))/;
    const schoolRegEx = /school(.qa|.dev|)((.eb.co.uk)|(.eb.com.au)|(.ebonline.in)|(.ebonline.co.nz)|(.ebonline.com)|(.eb.com))/;
    const bKidsRegEx = /kids(.qa|.dev|).britannica.com/;

    try {
      if (
        accountId &&
        (schoolRegEx.test(href) || bKidsRegEx.test(href) || k2LocalRegEx.test(href) || k2DevRegEx.test(href))
      ) {
        // we need to set the account ID, hostname override and override domain to send to the stats server
        setAccountId(accountId);

        // determine environment
        const environment = getEnvironmentFromPathname(href);

        // determine hostname override
        const hostnameOverride = getHostnameOverride(environment);

        setHostnameOverride(hostnameOverride);

        // determine override domain
        const overrideDomain = getOverrideDomain(startLanguage, environment, href);

        setOverrideDomain(overrideDomain);
      } else if (
        (href.includes('lz.local.britannica.com.br') || href.includes('lz.local.eb.com')) &&
        startLanguage === Locales.PORTUGUESE_BRAZIL
      ) {
        // This condition is for local testing of stats for Infantil
        setAccountId(InfantilAccountIds.DEV);
        setHostnameOverride(HostnameOverrides.DEV);
        setOverrideDomain(OverrideDomains.DEV.BR);
      } else {
        // This condition will default the stats config to whatever is
        // in the applicable environment variables
        // The key here is to test Infantil stats using the qa, staging
        // and master branch contexts in the Netlify.toml
        // The remainder of Netlify branches will also
        // default to whatever is in the environment variables for stats
        const { ACCOUNT_ID, HOST, HOSTNAME_OVERRIDE } = WebStats;

        setAccountId(ACCOUNT_ID);
        setHostnameOverride(HOSTNAME_OVERRIDE);
        setOverrideDomain(HOST);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  render() {
    const { pageDefaultTitle, pageTitleTemplate } = this.props;

    return (
      <Router history={history}>
        <RouteChange
          onRouteChange={[
            { name: 'backgroundChange', func: backGroundChange },
            { name: 'writeStat', func: AnalyticsApi.writeStat },
          ]}
        >
          <Helmet defaultTitle={pageDefaultTitle} titleTemplate={pageTitleTemplate} />
          <AppRoutes />
        </RouteChange>
      </Router>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func.isRequired,
  pageDefaultTitle: PropTypes.string.isRequired,
  pageTitleTemplate: PropTypes.string.isRequired,
  setAccountId: PropTypes.func.isRequired,
  setHostnameOverride: PropTypes.func.isRequired,
  setOverrideDomain: PropTypes.func.isRequired,
  startLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pageTitleTemplate: state.languageWidget.translation['shell-page-title-template'],
  pageDefaultTitle: state.languageWidget.translation['shell-page-default-title'],
  startLanguage: state.languageWidget.startLanguage,
});

export default withLocalize(connect(mapStateToProps, { setAccountId, setOverrideDomain, setHostnameOverride })(App));
