import { TOGGLE_PAUSE } from '../actionTypes';

const initialState = { pause: false };

const pause = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PAUSE: {
      window.sessionStorage.setItem('_eb_pause', !state.pause);
      window.dispatchEvent(new Event('_pause-change'));

      return { ...state, pause: !state.pause };
    }

    default: {
      return state;
    }
  }
};

export default pause;
