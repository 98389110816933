import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { camelCase } from 'change-case';
import { createAriaLabelPropKey } from '../PabloUtil';
import '../PabloStyles/DrawerButtonComponent.scss';

const DrawerButtonComponent = props => {
  const {
    drawerItem,
    setColorSelection,
    setStickerSelection,
    setBackgroundSelection,
    buttonClass,
    whichDrawerVisible,
    imgPathPrefix,
    // eslint-disable-next-line no-unused-vars
    playSound,
    // eslint-disable-next-line no-unused-vars
    colorName,
    isAnimating,
    ariaChecked,
  } = props;
  // DataImgSrcAttribute added to make sure the button, when the target, has the image source
  const DataImgSrcAttribute = imgPathPrefix ? { 'data-src': `${imgPathPrefix}${drawerItem}` } : '';

  return (
    <div className="DrawerButtonComponent">
      <button
        type="button"
        role="radio"
        // eslint-disable-next-line react/destructuring-assignment
        title={props[`${createAriaLabelPropKey(drawerItem, whichDrawerVisible, 'Sr')}`]}
        // eslint-disable-next-line react/destructuring-assignment
        aria-label={props[`${createAriaLabelPropKey(drawerItem, whichDrawerVisible, 'Sr')}`]}
        aria-checked={ariaChecked}
        className={buttonClass}
        onClick={event => {
          if (!isAnimating) {
            document.getElementsByName(drawerItem)[0].focus();

            switch (whichDrawerVisible) {
              case 'color':
                setColorSelection(drawerItem);
                // 'Uncomment to turn audio back on';
                // playSound(colorName);
                break;
              case 'sticker':
                setStickerSelection(drawerItem, event);
                break;
              case 'background':
                setBackgroundSelection(event);
                break;
              default:
                break;
            }
          }
        }}
        id={drawerItem}
        name={drawerItem}
        {...DataImgSrcAttribute}
      >
        {whichDrawerVisible === 'color' && (
          <div>
            <div className="color" name={drawerItem} data-color={drawerItem} aria-hidden="true" />
          </div>
        )}
        {(whichDrawerVisible === 'sticker' || whichDrawerVisible === 'background') && (
          <div
            className={`${classNames({
              'sixteen-nine-ratio': drawerItem === 'delete.svg',
              backgroundImageCont: drawerItem !== 'delete.svg',
            })}`}
          >
            {drawerItem !== 'delete.svg' && (
              <img
                // eslint-disable-next-line react/destructuring-assignment
                alt={props[`${camelCase(drawerItem.replace('.svg', ''))}Alt`]}
                src={`${imgPathPrefix}${drawerItem}`}
                name={drawerItem}
                className={classNames({
                  drawerImage: drawerItem !== 'delete.svg',
                })}
                aria-hidden="true"
              />
            )}
          </div>
        )}
      </button>
    </div>
  );
};

DrawerButtonComponent.propTypes = {
  drawerItem: PropTypes.string.isRequired,
  setColorSelection: PropTypes.func,
  setStickerSelection: PropTypes.func,
  setBackgroundSelection: PropTypes.func,
  buttonClass: PropTypes.string.isRequired,
  whichDrawerVisible: PropTypes.string.isRequired,
  imgPathPrefix: PropTypes.string,
  playSound: PropTypes.func.isRequired,
  colorName: PropTypes.string,
  isAnimating: PropTypes.bool.isRequired,
  ariaChecked: PropTypes.string,
};

DrawerButtonComponent.defaultProps = {
  imgPathPrefix: null,
  colorName: null,
  setColorSelection: null,
  setStickerSelection: null,
  setBackgroundSelection: null,
  ariaChecked: 'false',
};

const mapStateToProps = state => ({
  hotAirBalloonAlt: state.languageWidget.translation['pablo-alt-hot-air-balloon'],
  treeAlt: state.languageWidget.translation['pablo-alt-tree'],
  sunAlt: state.languageWidget.translation['pablo-alt-sun'],
  carAlt: state.languageWidget.translation['pablo-alt-car'],
  birdAlt: state.languageWidget.translation['pablo-alt-bird'],
  starAlt: state.languageWidget.translation['pablo-alt-star'],
  butterflyAlt: state.languageWidget.translation['pablo-alt-butterfly'],
  flowerAlt: state.languageWidget.translation['pablo-alt-flower'],
  cloudsAlt: state.languageWidget.translation['pablo-alt-clouds'],
  moonAndStarsAlt: state.languageWidget.translation['pablo-alt-moon-and-stars'],
  soccerStadiumAlt: state.languageWidget.translation['pablo-alt-soccer-stadium'],
  underwaterAlt: state.languageWidget.translation['pablo-alt-underwater'],
  deleteAlt: state.languageWidget.translation['pablo-alt-blank'],
  roomAlt: state.languageWidget.translation['pablo-alt-room'],
  spaceAlt: state.languageWidget.translation['pablo-alt-space'],
  beachAlt: state.languageWidget.translation['pablo-alt-beach'],
  libraryAlt: state.languageWidget.translation['pablo-alt-library'],
  moonscapeAlt: state.languageWidget.translation['pablo-alt-moonscape'],
  natureAlt: state.languageWidget.translation['pablo-alt-nature'],
  skyAlt: state.languageWidget.translation['pablo-alt-sky'],
  hotAirBalloonSr: state.languageWidget.translation['pablo-sr-hot-air-balloon'],
  treeSr: state.languageWidget.translation['pablo-sr-tree'],
  sunSr: state.languageWidget.translation['pablo-sr-sun'],
  carSr: state.languageWidget.translation['pablo-sr-car'],
  birdSr: state.languageWidget.translation['pablo-sr-bird'],
  starSr: state.languageWidget.translation['pablo-sr-star'],
  butterflySr: state.languageWidget.translation['pablo-sr-butterfly'],
  flowerSr: state.languageWidget.translation['pablo-sr-flower'],
  cloudsSr: state.languageWidget.translation['pablo-sr-clouds'],
  moonAndStarsSr: state.languageWidget.translation['pablo-sr-moon-and-stars'],
  soccerStadiumSr: state.languageWidget.translation['pablo-sr-soccer-stadium'],
  underwaterSr: state.languageWidget.translation['pablo-sr-underwater'],
  deleteSr: state.languageWidget.translation['pablo-sr-blank'],
  roomSr: state.languageWidget.translation['pablo-sr-room'],
  spaceSr: state.languageWidget.translation['pablo-sr-space'],
  beachSr: state.languageWidget.translation['pablo-sr-beach'],
  librarySr: state.languageWidget.translation['pablo-sr-library'],
  moonscapeSr: state.languageWidget.translation['pablo-sr-moonscape'],
  natureSr: state.languageWidget.translation['pablo-sr-nature'],
  skySr: state.languageWidget.translation['pablo-sr-sky'],
  redSr: state.languageWidget.translation['pablo-sr-red'],
  blueSr: state.languageWidget.translation['pablo-sr-blue'],
  brownSr: state.languageWidget.translation['pablo-sr-brown'],
  blackSr: state.languageWidget.translation['pablo-sr-black'],
  purpleSr: state.languageWidget.translation['pablo-sr-purple'],
  greenSr: state.languageWidget.translation['pablo-sr-green'],
  orangeSr: state.languageWidget.translation['pablo-sr-orange'],
  yellowSr: state.languageWidget.translation['pablo-sr-yellow'],
  redVioletSr: state.languageWidget.translation['pablo-sr-red-violet'],
  redOrangeSr: state.languageWidget.translation['pablo-sr-red-orange'],
  yellowGreenSr: state.languageWidget.translation['pablo-sr-yellow-green'],
  blueVioletSr: state.languageWidget.translation['pablo-sr-blue-violet'],
  carnationPinkSr: state.languageWidget.translation['pablo-sr-carnation-pink'],
  yellowOrangeSr: state.languageWidget.translation['pablo-sr-yellow-orange'],
  blueGreenSr: state.languageWidget.translation['pablo-sr-blue-green'],
  whiteSr: state.languageWidget.translation['pablo-sr-white'],
});

export default connect(mapStateToProps)(DrawerButtonComponent);
