import React from 'react';
import PropTypes from 'prop-types';
import './PabloStyles/PabloPage.scss';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import DefaultTemplate from '../Templates/Default/DefaultTemplate';
import { PabloConstants } from './PabloConstants';
import DrawerButtonComponent from './PabloPageComponents/DrawerButtonComponent';
import SelectionWindowComponent from './PabloPageComponents/SelectionWindowComponent';
import ToolbarButtonComponent from './PabloPageComponents/ToolbarButtonComponent';

const PabloPage = props => {
  const {
    isColorBarVisible,
    isStickerBarVisible,
    isBackgroundBarVisible,
    toolbarSelection,
    isAnimating,
    stickerbook,
    selectedBrush,
    selectedColor,
    selectedStickerImagePath,
    selectedStickerImage,
    selectedBackgroundImage,
    $leftToolbarContainer,
    $leftControlsCont,
    $leftDrawerCont,
    $backgroundsCont,
    $arrowLeft,
    $rightToolbarContainer,
    $rightDrawerCont,
    $stickerbookContainer,
    $stickersCont,
    $colorsCont,
    $arrowRight,
    $rightControlsCont,
    toggleDrawer,
    playSound,
    setToolbarSelection,
    setStateBrushSelection,
    print,
    setStickerbookBrushSelection,
    setBackgroundSelection,
    setStickerSelection,
    setColorSelection,
    undo,
    redo,
    colorDrawerLabel,
    stickerDrawerLabel,
    backgroundDrawerLabel,
    clearCanvas,
    historyLength,
    historyIndex,
  } = props;

  return (
    <DefaultTemplate>
      <div className="PabloPage">
        <div className="pablo-container" tabIndex={-1}>
          <SelectionWindowComponent
            selectedColor={selectedColor}
            selectedStickerImage={selectedStickerImage}
            selectedBrush={selectedBrush}
            toolbarSelection={toolbarSelection}
            selectedStickerImagePath={selectedStickerImagePath}
          />
          <div className="leftToolbarContainer" ref={$leftToolbarContainer}>
            <div className="controlsCont" id="leftControlsCont" ref={$leftControlsCont} role="radiogroup">
              {PabloConstants.TOOLBAR_TOOLS.map(tool => {
                if (tool.toolbarLocation === 'left') {
                  let ariaChecked = null;

                  if (
                    tool.toolName !== PabloConstants.TOOL.UNDO &&
                    tool.toolName !== PabloConstants.TOOL.REDO &&
                    tool.toolName !== PabloConstants.TOOL.CLEAR
                  ) {
                    if (toolbarSelection === tool.toolName) {
                      ariaChecked = 'true';
                    } else {
                      ariaChecked = 'false';
                    }
                  }

                  const turnOffHistoryTools =
                    ((tool.toolName === PabloConstants.TOOL.UNDO || tool.toolName === PabloConstants.TOOL.CLEAR) &&
                      historyIndex === -1) ||
                    (tool.toolName === PabloConstants.TOOL.REDO && historyIndex >= historyLength - 1);

                  return (
                    <ToolbarButtonComponent
                      toolbarItem={tool.toolName}
                      classList={`tool ${classNames({
                        active: toolbarSelection === tool.toolName,
                        'tool-hover':
                          (tool.toolName === PabloConstants.TOOL.UNDO && historyIndex >= 0) ||
                          (tool.toolName === PabloConstants.TOOL.REDO && historyIndex < historyLength - 1) ||
                          (tool.toolName !== PabloConstants.TOOL.UNDO &&
                            tool.toolName !== PabloConstants.TOOL.REDO &&
                            tool.toolName !== PabloConstants.TOOL.CLEAR) ||
                          (tool.toolName === PabloConstants.TOOL.CLEAR && historyIndex >= 0),
                        'no-tool-hover': turnOffHistoryTools,
                        'tool-inactive': turnOffHistoryTools,
                      })}`}
                      ariaHidden={turnOffHistoryTools ? 'true' : 'false'}
                      tabIndex={turnOffHistoryTools ? -1 : 0}
                      ariaChecked={ariaChecked}
                      toggleDrawer={toggleDrawer}
                      playSound={playSound}
                      setToolbarSelection={setToolbarSelection}
                      key={tool.toolName}
                      toolbarIcon={tool.toolIcon}
                      toolbarSelection={toolbarSelection}
                      isAnimating={isAnimating}
                      // Added in set brush attributes to accomodate new 'pattern' sticker logic
                      setStateBrushSelection={tool.brush ? setStateBrushSelection : null}
                      setStickerbookBrushSelection={tool.brush ? setStickerbookBrushSelection : null}
                      stickerbook={
                        // Uncomment to revert to original sticker logic
                        /* tool.toolName === PabloConstants.TOOL.STICKER || */ tool.toolName ===
                        PabloConstants.TOOL.SET_BACKGROUND
                          ? stickerbook
                          : null
                      }
                      undo={tool.toolName === PabloConstants.TOOL.UNDO ? undo : null}
                      redo={tool.toolName === PabloConstants.TOOL.REDO ? redo : null}
                      print={tool.toolName === PabloConstants.TOOL.PRINT ? print : null}
                      clearCanvas={tool.toolName === PabloConstants.TOOL.CLEAR ? clearCanvas : null}
                    />
                  );
                }

                return null;
              })}
            </div>
            <div className="drawerCont hideDrawer" id="leftDrawerCont" ref={$leftDrawerCont}>
              {
                <div
                  id="backgroundsCont"
                  ref={$backgroundsCont}
                  className={classNames({ hide: !isBackgroundBarVisible, '': isBackgroundBarVisible })}
                  role="radiogroup"
                  tabIndex={isBackgroundBarVisible ? '0' : '-1'}
                  aria-label={backgroundDrawerLabel}
                >
                  {PabloConstants.BACKGROUND_IMAGE_NAMES.map((backgroundImageName, index) => {
                    if (index % 2 === 0) {
                      return (
                        <Row key={backgroundImageName}>
                          <Col>
                            <DrawerButtonComponent
                              setBackgroundSelection={setBackgroundSelection}
                              drawerItem={backgroundImageName}
                              buttonClass={`background ${classNames({
                                activeBackground: selectedBackgroundImage === backgroundImageName,
                              })}`}
                              whichDrawerVisible="background"
                              imgPathPrefix={PabloConstants.BACKGROUNDS_PATH_PREFIX}
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={selectedBackgroundImage === backgroundImageName ? 'true' : 'false'}
                            />
                          </Col>
                          <Col>
                            <DrawerButtonComponent
                              setBackgroundSelection={setBackgroundSelection}
                              drawerItem={PabloConstants.BACKGROUND_IMAGE_NAMES[index + 1]}
                              buttonClass={`background ${classNames({
                                activeBackground:
                                  selectedBackgroundImage === PabloConstants.BACKGROUND_IMAGE_NAMES[index + 1],
                              })}`}
                              whichDrawerVisible="background"
                              imgPathPrefix={PabloConstants.BACKGROUNDS_PATH_PREFIX}
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={
                                selectedBackgroundImage === PabloConstants.BACKGROUND_IMAGE_NAMES[index + 1]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          </Col>
                        </Row>
                      );
                    }

                    return null;
                  })}
                </div>
              }
              <div id="arrow-left" ref={$arrowLeft} />
            </div>
          </div>
          <div className="rightToolbarContainer" ref={$rightToolbarContainer}>
            <div className="drawerCont hideDrawer" id="rightDrawerCont" ref={$rightDrawerCont}>
              {
                <div
                  id="stickersCont"
                  ref={$stickersCont}
                  className={classNames({ hide: !isStickerBarVisible, '': isStickerBarVisible })}
                  role="radiogroup"
                  tabIndex={isStickerBarVisible ? '0' : '-1'}
                  aria-label={stickerDrawerLabel}
                >
                  {PabloConstants.STICKER_IMAGE_NAMES.map((stickerImageName, index) => {
                    if (index % 2 === 0) {
                      return (
                        <Row key={stickerImageName}>
                          <Col>
                            <DrawerButtonComponent
                              setStickerSelection={setStickerSelection}
                              drawerItem={stickerImageName}
                              // Added in active class toggle to accomodate new "pattern" sticker logic
                              buttonClass={`sticker ${classNames({
                                activeSticker: selectedStickerImage === stickerImageName,
                              })}`}
                              whichDrawerVisible="sticker"
                              imgPathPrefix={PabloConstants.STICKERS_PATH_PREFIX}
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={selectedStickerImage === stickerImageName ? 'true' : 'false'}
                            />
                          </Col>
                          <Col>
                            <DrawerButtonComponent
                              setStickerSelection={setStickerSelection}
                              drawerItem={PabloConstants.STICKER_IMAGE_NAMES[index + 1]}
                              // Added in active class toggle to accomodate new "pattern" sticker logic
                              buttonClass={`sticker ${classNames({
                                activeSticker: selectedStickerImage === PabloConstants.STICKER_IMAGE_NAMES[index + 1],
                              })}`}
                              whichDrawerVisible="sticker"
                              imgPathPrefix={PabloConstants.STICKERS_PATH_PREFIX}
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={
                                selectedStickerImage === PabloConstants.STICKER_IMAGE_NAMES[index + 1]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          </Col>
                        </Row>
                      );
                    }

                    return null;
                  })}
                </div>
              }
              {
                <div
                  id="colorsCont"
                  ref={$colorsCont}
                  className={classNames({ hide: !isColorBarVisible, '': isColorBarVisible })}
                  role="radiogroup"
                  tabIndex={isColorBarVisible ? '0' : '-1'}
                  aria-label={colorDrawerLabel}
                >
                  {PabloConstants.ENABLED_COLORS.map((enabledColorReact, index) => {
                    if (index % 2 === 0) {
                      return (
                        <Row key={enabledColorReact.name}>
                          <Col>
                            <DrawerButtonComponent
                              setColorSelection={setColorSelection}
                              drawerItem={enabledColorReact.hex}
                              colorName={enabledColorReact.name}
                              buttonClass={`colorBtn ${classNames({
                                activeColor: enabledColorReact.hex === selectedColor,
                              })}`}
                              whichDrawerVisible="color"
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={enabledColorReact.hex === selectedColor ? 'true' : 'false'}
                            />
                          </Col>
                          <Col>
                            <DrawerButtonComponent
                              setColorSelection={setColorSelection}
                              drawerItem={PabloConstants.ENABLED_COLORS[index + 1].hex}
                              colorName={PabloConstants.ENABLED_COLORS[index + 1].name}
                              buttonClass={`colorBtn ${classNames({
                                activeColor: PabloConstants.ENABLED_COLORS[index + 1].hex === selectedColor,
                              })}`}
                              whichDrawerVisible="color"
                              playSound={playSound}
                              isAnimating={isAnimating}
                              ariaChecked={
                                PabloConstants.ENABLED_COLORS[index + 1].hex === selectedColor ? 'true' : 'false'
                              }
                            />
                          </Col>
                        </Row>
                      );
                    }

                    return null;
                  })}
                </div>
              }
              <div id="arrow-right" ref={$arrowRight} />
            </div>
            <div className="controlsCont" id="rightControlsCont" ref={$rightControlsCont} role="radiogroup">
              {PabloConstants.TOOLBAR_TOOLS.map(tool => {
                if (tool.toolbarLocation === 'right') {
                  return (
                    <ToolbarButtonComponent
                      toolbarItem={tool.toolName}
                      classList={`tool ${classNames({
                        brush: tool.brush,
                        '': !tool.brush,
                        active: toolbarSelection === tool.toolName,
                      })}`}
                      ariaChecked={toolbarSelection === tool.toolName ? 'true' : 'false'}
                      toggleDrawer={toggleDrawer}
                      playSound={playSound}
                      setToolbarSelection={setToolbarSelection}
                      setStateBrushSelection={tool.brush ? setStateBrushSelection : null}
                      setStickerbookBrushSelection={tool.brush ? setStickerbookBrushSelection : null}
                      key={tool.toolName}
                      toolbarIcon={tool.toolIcon}
                      toolbarSelection={toolbarSelection}
                      isAnimating={isAnimating}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div>
          <div id="stickerbook_container" ref={$stickerbookContainer} />
        </div>
      </div>
    </DefaultTemplate>
  );
};

PabloPage.propTypes = {
  isColorBarVisible: PropTypes.bool.isRequired,
  isStickerBarVisible: PropTypes.bool.isRequired,
  isBackgroundBarVisible: PropTypes.bool.isRequired,
  toolbarSelection: PropTypes.string.isRequired,
  isAnimating: PropTypes.bool.isRequired,
  stickerbook: PropTypes.shape().isRequired,
  selectedBrush: PropTypes.string.isRequired,
  selectedColor: PropTypes.string.isRequired,
  selectedStickerImagePath: PropTypes.string.isRequired,
  selectedStickerImage: PropTypes.string.isRequired,
  selectedBackgroundImage: PropTypes.string.isRequired,
  undo: PropTypes.func.isRequired,
  redo: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  playSound: PropTypes.func.isRequired,
  setToolbarSelection: PropTypes.func.isRequired,
  setStateBrushSelection: PropTypes.func.isRequired,
  setStickerSelection: PropTypes.func.isRequired,
  setColorSelection: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  setStickerbookBrushSelection: PropTypes.func.isRequired,
  setBackgroundSelection: PropTypes.func.isRequired,
  colorDrawerLabel: PropTypes.string.isRequired,
  backgroundDrawerLabel: PropTypes.string.isRequired,
  stickerDrawerLabel: PropTypes.string.isRequired,
  $leftToolbarContainer: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $leftControlsCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $leftDrawerCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $backgroundsCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $arrowLeft: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $rightToolbarContainer: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $rightDrawerCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $stickerbookContainer: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $stickersCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $colorsCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $arrowRight: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  $rightControlsCont: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  clearCanvas: PropTypes.func.isRequired,
  historyLength: PropTypes.number.isRequired,
  historyIndex: PropTypes.number.isRequired,
};

export default PabloPage;
