import getBackgroundClassFromRoute from '../Pages/Templates/TemplateUtil';

function backGroundChange(location) {
  const bgClass = getBackgroundClassFromRoute(location);

  document.getElementsByTagName('body')[0].classList = '';
  document.getElementsByTagName('body')[0].classList.add(bgClass);
}

function getQueryParameter(key) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');

    if (pair[0] === key) {
      return pair[1];
    }
  }

  return null;
}

export { backGroundChange, getQueryParameter };
