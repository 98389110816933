import { Howler } from 'howler';
import { TOGGLE_AUDIO } from '../actionTypes';

const initialState = { audio: true };

const audio = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AUDIO: {
      window.sessionStorage.setItem('_eb_sound', !state.audio);

      Howler.mute(state.audio);

      return { ...state, audio: !state.audio };
    }

    default: {
      return state;
    }
  }
};

export default audio;
