import React from 'react';
import PropTypes from 'prop-types';
import SoundWidget from '../SoundWidget/SoundWidget';
import LanguageWidget from '../LanguageWidget/LanguageWidget';
import BackToHome from '../BackToHome/BackToHome';
import SkipToMainContentLink from '../SkipToMainContentLink/SkipToMainContentLink';
import './Header.scss';

const Header = props => {
  const { startLanguage } = props;

  return (
    <header className="Header">
      <nav role="navigation" className="header-nav">
        <SkipToMainContentLink />
        <BackToHome />
        <SoundWidget />
        <LanguageWidget hide language={startLanguage} />
      </nav>
    </header>
  );
};

Header.propTypes = {
  startLanguage: PropTypes.string.isRequired,
};

export default Header;
