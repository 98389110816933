import { HostnameOverrides, OverrideDomains, Environments, Locales } from '../constants';

const ProductionOverrideDomains = OverrideDomains.PROD;
const QAOverrideDomains = OverrideDomains.QA;
const DevOverrideDomains = OverrideDomains.DEV;

/**
 * @param   {[String]} locationCode [Location code for market]
 * @param   {[String]} environment  [Environment: prod, dev, qa]
 * @param   {[String]} pathname     [Current pathname]
 * @returns {[String]}              [Override domain for stats]
 */

export function getOverrideDomain(locationCode, environment, pathname) {
  // NEED TO UPDATE WITH NEW MARKET
  if (pathname.indexOf('.ebonline.com') !== -1 && locationCode === Locales.ENGLISH_ASIA) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.ASIA;
      case Environments.QA:
        return QAOverrideDomains.ASIA;
      case Environments.DEV:
        return DevOverrideDomains.ASIA;
      default:
        return null;
    }
  }

  if (pathname.indexOf('.ebonline.co.nz') !== -1 && locationCode === Locales.ENGLISH_NEW_ZEALAND) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.NZ;
      case Environments.QA:
        return QAOverrideDomains.NZ;
      case Environments.DEV:
        return DevOverrideDomains.NZ;
      default:
        return null;
    }
  }

  if (pathname.indexOf('.ebonline.in') !== -1 && locationCode === Locales.ENGLISH_INDIA) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.IN;
      case Environments.QA:
        return QAOverrideDomains.IN;
      case Environments.DEV:
        return DevOverrideDomains.IN;
      default:
        return null;
    }
  }

  if (pathname.indexOf('.com.au') !== -1 && locationCode === Locales.ENGLISH_AUSTRALIA) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.AU;
      case Environments.QA:
        return QAOverrideDomains.AU;
      case Environments.DEV:
        return DevOverrideDomains.AU;
      default:
        return null;
    }
  }

  if (pathname.indexOf('.co.uk') !== -1 && locationCode === Locales.ENGLISH_UNITED_KINGDOM) {
    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.GB;
      case Environments.QA:
        return QAOverrideDomains.GB;
      case Environments.DEV:
        return DevOverrideDomains.GB;
      default:
        return null;
    }
  }

  if (locationCode === Locales.ENGLISH_US) {
    const bKidsRegEx = /kids(.qa|.dev|).britannica.com/;

    if (bKidsRegEx.test(pathname)) {
      switch (environment) {
        case Environments.PRODUCTION:
          return ProductionOverrideDomains.BKIDS.US;
        case Environments.QA:
          return QAOverrideDomains.BKIDS.US;
        case Environments.DEV:
          return DevOverrideDomains.BKIDS.US;
        default:
          return null;
      }
    }

    switch (environment) {
      case Environments.PRODUCTION:
        return ProductionOverrideDomains.US;
      case Environments.QA:
        return QAOverrideDomains.US;
      case Environments.DEV:
        return DevOverrideDomains.US;
      default:
        return null;
    }
  }

  return null;
}

/**
 * @param   {[String]} pathname [Current pathname]
 * @returns {[String]}          [Environment: prod, dev, qa]
 */

export function getEnvironmentFromPathname(pathname) {
  // NEED TO UPDATE WITH NEW MARKET
  if (
    pathname.includes(ProductionOverrideDomains.US) ||
    pathname.includes(ProductionOverrideDomains.GB) ||
    pathname.includes(ProductionOverrideDomains.AU) ||
    pathname.includes(ProductionOverrideDomains.IN) ||
    pathname.includes(ProductionOverrideDomains.NZ) ||
    pathname.includes(ProductionOverrideDomains.ASIA) ||
    pathname.includes(ProductionOverrideDomains.BKIDS.US)
  ) {
    return Environments.PRODUCTION;
  }

  if (pathname.includes('.dev.') || pathname.includes('k2.local.eb.com') || pathname.includes('lz.local.eb.com')) {
    return Environments.DEV;
  }

  if (pathname.includes('.qa.')) {
    return Environments.QA;
  }

  return Environments.DEV;
}

/**
 * @param   {[String]} environment [Environment: prod, dev, qa]
 * @returns {[String]}             [Hostname override for stats]
 */

export function getHostnameOverride(environment) {
  switch (environment) {
    case Environments.PRODUCTION:
      return HostnameOverrides.PROD;
    case Environments.QA:
      return HostnameOverrides.QA;
    case Environments.DEV:
      return HostnameOverrides.DEV;
    default:
      return null;
  }
}

export default { getHostnameOverride, getEnvironmentFromPathname, getOverrideDomain };
