import { getLanguageData } from '../../Utils/localeUtilities';
import { Endpoint } from '../../constants';

const languageJson = getLanguageData();
const teacherGuideLinkEnd = languageJson['footer-link-teacher-guide'];

let teacherGuideLink = '';

if (teacherGuideLinkEnd !== 'null') {
  teacherGuideLink = `${Endpoint.CDN_URL}${teacherGuideLinkEnd}`;
} else {
  teacherGuideLink = teacherGuideLinkEnd;
}

export const FooterConstants = {
  LINKS: [
    {
      href: languageJson['footer-link-contact'],
      name: languageJson['footer-link-name-contact'],
    },
    {
      href: languageJson['footer-link-copyright'],
      name: languageJson['footer-link-name-copyright'],
    },
    {
      href: languageJson['footer-link-privacy'],
      name: languageJson['footer-link-name-privacy'],
    },
    {
      href: languageJson['footer-link-resources'],
      name: languageJson['footer-link-name-resources'],
    },
    {
      href: languageJson['footer-link-app-information'],
      name: languageJson['footer-link-name-app-information'],
    },
    {
      href: teacherGuideLink,
      name: languageJson['footer-link-name-teacher-guide'],
    },
    {
      href: languageJson['footer-link-about'],
      name: languageJson['footer-link-name-about'],
    },
  ],
};

export default FooterConstants;
