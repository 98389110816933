export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';
export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const TOGGLE_AIRDROPANIMATION = 'TOGGLE_AIRDROPANIMATION';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';
export const SET_HOSTNAME_OVERRIDE = 'SET_HOSTNAME_OVERRIDE';
export const SET_OVERRIDE_DOMAIN = 'SET_OVERRIDE_DOMAIN';
export const TOGGLE_PAUSE = 'TOGGLE_PAUSE';

export default {
  TOGGLE_AUDIO,
  TOGGLE_LANGUAGE,
  SWITCH_LANGUAGE,
  TOGGLE_AIRDROPANIMATION,
  SET_ACCOUNT_ID,
  SET_HOSTNAME_OVERRIDE,
  SET_OVERRIDE_DOMAIN,
  TOGGLE_PAUSE,
};
