import {
  faIgloo,
  faTree,
  faGlobeAmericas,
  faSnowflake,
  faSun,
  faTreePalm,
  faWheat,
} from '@fortawesome/pro-solid-svg-icons';

const BiomeTypes = {
  TUNDRA: 'tundra',
  DESERT: 'desert',
  TAIGA: 'taiga',
  TEMPERATE_FOREST: 'temperateForest',
  TROPICAL_FOREST: 'tropicalForest',
  GRASSLAND: 'grassland',
};

const BiomeInfoButtons = [
  {
    name: 'tundra',
    translationName: 'mercator-string-biome-name-tundra',
    translationKey: 'mercator-string-tundra-button-text',
    icon: faIgloo,
    className: 'biome-toggle tundra',
    elementId: 'tundra-toggle',
  },
  {
    name: 'taiga',
    translationName: 'mercator-string-biome-name-taiga',
    translationKey: 'mercator-string-taiga-button-text',
    icon: faSnowflake,
    className: 'biome-toggle taiga',
    elementId: 'taiga-toggle',
  },
  {
    name: 'temperate-forest',
    translationName: 'mercator-string-biome-name-temp-forest',
    translationKey: 'mercator-string-temp-forest-button-text',
    icon: faTree,
    className: 'biome-toggle temperateForest',
    elementId: 'temperate-forest-toggle',
  },
  {
    name: 'tropical-forest',
    translationName: 'mercator-string-biome-name-trop-forest',
    translationKey: 'mercator-string-trop-forest-button-text',
    icon: faTreePalm,
    className: 'biome-toggle tropicalForest',
    elementId: 'tropical-forest-toggle',
  },
  {
    name: 'grassland',
    translationName: 'mercator-string-biome-name-grassland',
    translationKey: 'mercator-string-grassland-button-text',
    icon: faWheat,
    className: 'biome-toggle grassland',
    elementId: 'grassland-toggle',
  },
  {
    name: 'desert',
    translationName: 'mercator-string-biome-name-desert',
    translationKey: 'mercator-string-desert-button-text',
    icon: faSun,
    className: 'biome-toggle desert',
    elementId: 'desert-toggle',
  },
  {
    name: 'earth',
    translationName: 'mercator-string-biome-name-earth',
    translationKey: 'mercator-string-earth-button-text',
    icon: faGlobeAmericas,
    elementId: 'earth-toggle',
  },
];

const AssemblyDefinitions = {
  _0001: {
    'pt-BR': 239202,
    'en-US': 239125,
    'en-GB': 239125,
    'en-AU': 239125,
    'en-IN': 239125,
    'en-NZ': 239125,
    'en-AS': 239125,
  },
  _0002: {
    'pt-BR': 239205,
    'en-US': 239128,
    'en-GB': 239128,
    'en-AU': 239128,
    'en-IN': 239128,
    'en-NZ': 239128,
    'en-AS': 239128,
  },
  _0003: {
    'pt-BR': 239200,
    'en-US': 239123,
    'en-GB': 239123,
    'en-AU': 239123,
    'en-IN': 239123,
    'en-NZ': 239123,
    'en-AS': 239123,
  },
  _0004: {
    'pt-BR': 239201,
    'en-US': 239124,
    'en-GB': 239124,
    'en-AU': 239124,
    'en-IN': 239124,
    'en-NZ': 239124,
    'en-AS': 239124,
  },
  _0005: {
    'pt-BR': 239204,
    'en-US': 239127,
    'en-GB': 239127,
    'en-AU': 239127,
    'en-IN': 239127,
    'en-NZ': 239127,
    'en-AS': 239127,
  },
  _0006: {
    'pt-BR': 239198,
    'en-US': 239122,
    'en-GB': 239122,
    'en-AU': 239122,
    'en-IN': 239122,
    'en-NZ': 239122,
    'en-AS': 239122,
  },
  _0007: {
    'pt-BR': 239199,
    'en-US': 239180,
    'en-GB': 239180,
    'en-AU': 239180,
    'en-IN': 239180,
    'en-NZ': 239180,
    'en-AS': 239180,
  },
  _0008: {
    'pt-BR': 239203,
    'en-US': 239126,
    'en-GB': 239126,
    'en-AU': 239126,
    'en-IN': 239126,
    'en-NZ': 239126,
    'en-AS': 239126,
  },
  _0009: {
    'pt-BR': 239227,
    'en-US': 239150,
    'en-GB': 239150,
    'en-AU': 239150,
    'en-IN': 239150,
    'en-NZ': 239150,
    'en-AS': 239150,
  },
  _0010: {
    'pt-BR': 239225,
    'en-US': 239148,
    'en-GB': 239148,
    'en-AU': 239148,
    'en-IN': 239148,
    'en-NZ': 239148,
    'en-AS': 239148,
  },
  _0011: {
    'pt-BR': 239228,
    'en-US': 239151,
    'en-GB': 239151,
    'en-AU': 239151,
    'en-IN': 239151,
    'en-NZ': 239151,
    'en-AS': 239151,
  },
  _0012: {
    'pt-BR': 239229,
    'en-US': 239152,
    'en-GB': 239152,
    'en-AU': 239152,
    'en-IN': 239152,
    'en-NZ': 239152,
    'en-AS': 239152,
  },
  _0013: {
    'pt-BR': 239224,
    'en-US': 239147,
    'en-GB': 239147,
    'en-AU': 239147,
    'en-IN': 239147,
    'en-NZ': 239147,
    'en-AS': 239147,
  },
  _0014: {
    'pt-BR': 239230,
    'en-US': 239153,
    'en-GB': 239153,
    'en-AU': 239153,
    'en-IN': 239153,
    'en-NZ': 239153,
    'en-AS': 239153,
  },
  _0015: {
    'pt-BR': 239231,
    'en-US': 239154,
    'en-GB': 239154,
    'en-AU': 239154,
    'en-IN': 239154,
    'en-NZ': 239154,
    'en-AS': 239154,
  },
  _0016: {
    'pt-BR': 239232,
    'en-US': 239155,
    'en-GB': 239155,
    'en-AU': 239155,
    'en-IN': 239155,
    'en-NZ': 239155,
    'en-AS': 239155,
  },
  _0017: {
    'pt-BR': 239226,
    'en-US': 239149,
    'en-GB': 239149,
    'en-AU': 239149,
    'en-IN': 239149,
    'en-NZ': 239149,
    'en-AS': 239149,
  },
  _0018: {
    'pt-BR': 239210,
    'en-US': 239133,
    'en-GB': 239133,
    'en-AU': 239133,
    'en-IN': 239133,
    'en-NZ': 239133,
    'en-AS': 239133,
  },
  _0019: {
    'pt-BR': 239212,
    'en-US': 239135,
    'en-GB': 239135,
    'en-AU': 239135,
    'en-IN': 239135,
    'en-NZ': 239135,
    'en-AS': 239135,
  },
  _0020: {
    'pt-BR': 239213,
    'en-US': 239136,
    'en-GB': 239136,
    'en-AU': 239136,
    'en-IN': 239136,
    'en-NZ': 239136,
    'en-AS': 239136,
  },
  _0021: {
    'pt-BR': 239209,
    'en-US': 239132,
    'en-GB': 239132,
    'en-AU': 239132,
    'en-IN': 239132,
    'en-NZ': 239132,
    'en-AS': 239132,
  },
  _0022: {
    'pt-BR': 239211,
    'en-US': 239134,
    'en-GB': 239134,
    'en-AU': 239134,
    'en-IN': 239134,
    'en-NZ': 239134,
    'en-AS': 239134,
  },
  _0023: {
    'pt-BR': 239207,
    'en-US': 239130,
    'en-GB': 239130,
    'en-AU': 239130,
    'en-IN': 239130,
    'en-NZ': 239130,
    'en-AS': 239130,
  },
  _0024: {
    'pt-BR': 239206,
    'en-US': 239129,
    'en-GB': 239129,
    'en-AU': 239129,
    'en-IN': 239129,
    'en-NZ': 239129,
    'en-AS': 239129,
  },
  _0025: {
    'pt-BR': 239208,
    'en-US': 239131,
    'en-GB': 239131,
    'en-AU': 239131,
    'en-IN': 239131,
    'en-NZ': 239131,
    'en-AS': 239131,
  },
  _0026: {
    'pt-BR': 239222,
    'en-US': 239145,
    'en-GB': 239145,
    'en-AU': 239145,
    'en-IN': 239145,
    'en-NZ': 239145,
    'en-AS': 239145,
  },
  _0027: {
    'pt-BR': 239214,
    'en-US': 239137,
    'en-GB': 239137,
    'en-AU': 239137,
    'en-IN': 239137,
    'en-NZ': 239137,
    'en-AS': 239137,
  },
  _0028: {
    'pt-BR': 239221,
    'en-US': 239144,
    'en-GB': 239144,
    'en-AU': 239144,
    'en-IN': 239144,
    'en-NZ': 239144,
    'en-AS': 239144,
  },
  _0029: {
    'pt-BR': 239217,
    'en-US': 239140,
    'en-GB': 239140,
    'en-AU': 239140,
    'en-IN': 239140,
    'en-NZ': 239140,
    'en-AS': 239140,
  },
  _0030: {
    'pt-BR': 239220,
    'en-US': 239143,
    'en-GB': 239143,
    'en-AU': 239143,
    'en-IN': 239143,
    'en-NZ': 239143,
    'en-AS': 239143,
  },
  _0031: {
    'pt-BR': 239216,
    'en-US': 239139,
    'en-GB': 239139,
    'en-AU': 239139,
    'en-IN': 239139,
    'en-NZ': 239139,
    'en-AS': 239139,
  },
  _0032: {
    'pt-BR': 239215,
    'en-US': 239138,
    'en-GB': 239138,
    'en-AU': 239138,
    'en-IN': 239138,
    'en-NZ': 239138,
    'en-AS': 239138,
  },
  _0033: {
    'pt-BR': 239218,
    'en-US': 239141,
    'en-GB': 239141,
    'en-AU': 239141,
    'en-IN': 239141,
    'en-NZ': 239141,
    'en-AS': 239141,
  },
  _0034: {
    'pt-BR': 239219,
    'en-US': 239142,
    'en-GB': 239142,
    'en-AU': 239142,
    'en-IN': 239142,
    'en-NZ': 239142,
    'en-AS': 239142,
  },
  _0035: {
    'pt-BR': 239223,
    'en-US': 239146,
    'en-GB': 239146,
    'en-AU': 239146,
    'en-IN': 239146,
    'en-NZ': 239146,
    'en-AS': 239146,
  },
  _0036: {
    'pt-BR': 239181,
    'en-US': 239105,
    'en-GB': 239105,
    'en-AU': 239105,
    'en-IN': 239105,
    'en-NZ': 239105,
    'en-AS': 239105,
  },
  _0037: {
    'pt-BR': 239182,
    'en-US': 239106,
    'en-GB': 239106,
    'en-AU': 239106,
    'en-IN': 239106,
    'en-NZ': 239106,
    'en-AS': 239106,
  },
  _0038: {
    'pt-BR': 239184,
    'en-US': 239108,
    'en-GB': 239108,
    'en-AU': 239108,
    'en-IN': 239108,
    'en-NZ': 239108,
    'en-AS': 239108,
  },
  _0039: {
    'pt-BR': 239183,
    'en-US': 239107,
    'en-GB': 239107,
    'en-AU': 239107,
    'en-IN': 239107,
    'en-NZ': 239107,
    'en-AS': 239107,
  },
  _0040: {
    'pt-BR': 239185,
    'en-US': 239109,
    'en-GB': 239109,
    'en-AU': 239109,
    'en-IN': 239109,
    'en-NZ': 239109,
    'en-AS': 239109,
  },
  _0041: {
    'pt-BR': 239186,
    'en-US': 239110,
    'en-GB': 239110,
    'en-AU': 239110,
    'en-IN': 239110,
    'en-NZ': 239110,
    'en-AS': 239110,
  },
  _0042: {
    'pt-BR': 239188,
    'en-US': 239112,
    'en-GB': 239112,
    'en-AU': 239112,
    'en-IN': 239112,
    'en-NZ': 239112,
    'en-AS': 239112,
  },
  _0043: {
    'pt-BR': 239187,
    'en-US': 239111,
    'en-GB': 239111,
    'en-AU': 239111,
    'en-IN': 239111,
    'en-NZ': 239111,
    'en-AS': 239111,
  },
  _0044: {
    'pt-BR': 239189,
    'en-US': 239113,
    'en-GB': 239113,
    'en-AU': 239113,
    'en-IN': 239113,
    'en-NZ': 239113,
    'en-AS': 239113,
  },
  _0045: {
    'pt-BR': 239195,
    'en-US': 239119,
    'en-GB': 239119,
    'en-AU': 239119,
    'en-IN': 239119,
    'en-NZ': 239119,
    'en-AS': 239119,
  },
  _0046: {
    'pt-BR': 239191,
    'en-US': 239115,
    'en-GB': 239115,
    'en-AU': 239115,
    'en-IN': 239115,
    'en-NZ': 239115,
    'en-AS': 239115,
  },
  _0047: {
    'pt-BR': 239192,
    'en-US': 239116,
    'en-GB': 239116,
    'en-AU': 239116,
    'en-IN': 239116,
    'en-NZ': 239116,
    'en-AS': 239116,
  },
  _0048: {
    'pt-BR': 239196,
    'en-US': 239120,
    'en-GB': 239120,
    'en-AU': 239120,
    'en-IN': 239120,
    'en-NZ': 239120,
    'en-AS': 239120,
  },
  _0049: {
    'pt-BR': 239190,
    'en-US': 239114,
    'en-GB': 239114,
    'en-AU': 239114,
    'en-IN': 239114,
    'en-NZ': 239114,
    'en-AS': 239114,
  },
  _0050: {
    'pt-BR': 239193,
    'en-US': 239117,
    'en-GB': 239117,
    'en-AU': 239117,
    'en-IN': 239117,
    'en-NZ': 239117,
    'en-AS': 239117,
  },
  _0051: {
    'pt-BR': 239197,
    'en-US': 239121,
    'en-GB': 239121,
    'en-AU': 239121,
    'en-IN': 239121,
    'en-NZ': 239121,
    'en-AS': 239121,
  },
  _0052: {
    'pt-BR': 239194,
    'en-US': 239118,
    'en-GB': 239118,
    'en-AU': 239118,
    'en-IN': 239118,
    'en-NZ': 239118,
    'en-AS': 239118,
  },
};

const SpotlightDefinitions = {
  taiga: {
    'pt-BR': 239689,
    'en-US': 239697,
    'en-GB': 239697,
    'en-AU': 239697,
    'en-IN': 239697,
    'en-NZ': 239697,
    'en-AS': 239697,
  },
  grassland: {
    'pt-BR': 239685,
    'en-US': 239693,
    'en-GB': 239693,
    'en-AU': 239693,
    'en-IN': 239693,
    'en-NZ': 239693,
    'en-AS': 239693,
  },
  desert: {
    'pt-BR': 239684,
    'en-US': 239692,
    'en-GB': 239692,
    'en-AU': 239692,
    'en-IN': 239692,
    'en-NZ': 239692,
    'en-AS': 239692,
  },
  tropicalForest: {
    'pt-BR': 239686,
    'en-US': 239694,
    'en-GB': 239694,
    'en-AU': 239694,
    'en-IN': 239694,
    'en-NZ': 239694,
    'en-AS': 239694,
  },
  temperateForest: {
    'pt-BR': 239687,
    'en-US': 239695,
    'en-GB': 239695,
    'en-AU': 239695,
    'en-IN': 239695,
    'en-NZ': 239695,
    'en-AS': 239695,
  },
  tundra: {
    'pt-BR': 239688,
    'en-US': 239696,
    'en-GB': 239696,
    'en-AU': 239696,
    'en-IN': 239696,
    'en-NZ': 239696,
    'en-AS': 239696,
  },
};

const AnimationHelpers = {
  ANIMATE_IN: 'animate_locales_in',
  ANIMATE_OUT: 'animate_locales_out',
};

export { BiomeTypes, AssemblyDefinitions, BiomeInfoButtons, AnimationHelpers, SpotlightDefinitions };
