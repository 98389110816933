// We can't use constants in this file as it is invoked fron
// a call within the constants file.

import br from '../i18n/localizations/pt-BR.json';
import en from '../i18n/localizations/en-US.json';
import gb from '../i18n/localizations/en-GB.json';
import au from '../i18n/localizations/en-AU.json';
import nz from '../i18n/localizations/en-NZ.json';
import asia from '../i18n/localizations/en-AS.json';
import india from '../i18n/localizations/en-IN.json';

function getQueryParameter(key) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');

    if (pair[0] === key) {
      return pair[1];
    }
  }

  return null;
}

export function getLanguageData() {
  // NEED TO UPDATE WITH NEW MARKET
  const uri = window.location.host;

  // this was breaking shit
  if (process.env.REACT_APP_ENV === 'dev') {
    // Check to see if there are locale query parameters
    const locale = getQueryParameter('locale');

    if (locale) {
      if (locale === 'en-US') {
        return en;
      }

      if (locale === 'pt-BR') {
        return br;
      }

      if (locale === 'en-AU') {
        return au;
      }

      if (locale === 'en-GB') {
        return gb;
      }

      if (locale === 'en-NZ') {
        return nz;
      }

      if (locale === 'en-IN') {
        return india;
      }

      if (locale === 'en-AS') {
        return asia;
      }
    } else {
      if (uri.indexOf('.com.br') !== -1) {
        return br;
      }

      if (uri.indexOf('.co.uk') !== -1) {
        return gb;
      }

      if (uri.indexOf('.com.au') !== -1) {
        return au;
      }

      if (uri.indexOf('.ebonline.in') !== -1) {
        return india;
      }

      if (uri.indexOf('.ebonline.co.nz') !== -1) {
        return nz;
      }

      if (uri.indexOf('.ebonline.com') !== -1) {
        return asia;
      }

      return en;
    }
  } else {
    if (uri.indexOf('.com.br') !== -1) {
      return br;
    }

    if (uri.indexOf('.co.uk') !== -1) {
      return gb;
    }

    if (uri.indexOf('.com.au') !== -1) {
      return au;
    }

    if (uri.indexOf('.ebonline.in') !== -1) {
      return india;
    }

    if (uri.indexOf('.ebonline.co.nz') !== -1) {
      return nz;
    }

    if (uri.indexOf('.ebonline.com') !== -1) {
      return asia;
    }

    return en;
  }

  return en;
}

export function getLocationCode() {
  // NEED TO UPDATE WITH NEW MARKET
  const uri = window.location.host;

  if (process.env.REACT_APP_ENV === 'dev') {
    // Check to see if there are locale query parameters
    const locale = getQueryParameter('locale');

    if (locale) {
      switch (locale) {
        case 'en-US':
          return 'en-US';
        case 'pt-BR':
          return 'pt-BR';
        case 'en-GB':
          return 'en-GB';
        case 'en-AU':
          return 'en-AU';
        case 'en-NZ':
          return 'en-NZ';
        case 'en-IN':
          return 'en-IN';
        case 'en-AS':
          return 'en-AS';
        default:
          return 'en-US';
      }
    } else {
      if (uri.indexOf('.com.br') !== -1) {
        return 'pt-BR';
      }

      if (uri.indexOf('.co.uk') !== -1) {
        return 'en-GB';
      }

      if (uri.indexOf('.ebonline.in') !== -1) {
        return 'en-IN';
      }

      if (uri.indexOf('.com.au') !== -1) {
        return 'en-AU';
      }

      if (uri.indexOf('.ebonline.co.nz') !== -1) {
        return 'en-NZ';
      }

      if (uri.indexOf('.ebonline.com') !== -1) {
        return 'en-AS';
      }

      return 'en-US';
    }
  } else {
    // If we are not in the development environment
    if (uri.indexOf('.com.br') !== -1) {
      return 'pt-BR';
    }

    if (uri.indexOf('.co.uk') !== -1) {
      return 'en-GB';
    }

    if (uri.indexOf('.ebonline.in') !== -1) {
      return 'en-IN';
    }

    if (uri.indexOf('.com.au') !== -1) {
      return 'en-AU';
    }

    if (uri.indexOf('.ebonline.co.nz') !== -1) {
      return 'en-NZ';
    }

    if (uri.indexOf('.ebonline.com') !== -1) {
      return 'en-AS';
    }

    return 'en-US';
  }
}
