import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './HomePageButtonLink.scss';

const HomePageButtonLink = props => {
  const { animationStart, imgSrc, imgAltText, label, animateAllIcons, linkHref, playNavVoiceover } = props;

  const btnClass = animationStart
    ? cx('button', 'hp--ratio--button', 'animation', animationStart, { 'nav-button': playNavVoiceover })
    : cx('button', 'hp--ratio--button');

  return (
    <button
      type="button"
      data-href={linkHref}
      data-name={label.toLowerCase()}
      className={btnClass}
      onClick={animateAllIcons}
    >
      <img className="icon" data-name={label.toLowerCase()} src={imgSrc} alt={imgAltText} aria-hidden="true" />
      <div className="hp--button--label" data-name={label.toLowerCase()}>
        {label}
      </div>
    </button>
  );
};

HomePageButtonLink.propTypes = {
  animationStart: PropTypes.string,
  linkHref: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAltText: PropTypes.string,
  label: PropTypes.string,
  animateAllIcons: PropTypes.func.isRequired,
  playNavVoiceover: PropTypes.bool.isRequired,
};

HomePageButtonLink.defaultProps = {
  animationStart: null,
  linkHref: null,
  imgSrc: null,
  imgAltText: null,
  label: null,
};

export default HomePageButtonLink;
