import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './BrowseNavTabComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AccesibleIconButton from '../AccessibleIconButton/AccessibleIconButton';

const BrowseNavTabComponent = props => {
  const { isActive, dataCategory, handleClick, categoriesName, className, icon, bucketId } = props;
  const bucketClass = bucketId === 0 ? 'play' : 'read';

  return (
    <>
      {handleClick && (
        <>
          <button
            aria-selected={isActive}
            aria-controls="browse-list"
            aria-label={categoriesName}
            className={cx({ active: isActive }, 'browse-nav-tab-component', bucketClass)}
            data-category={dataCategory}
            // key={elementId}
            onClick={handleClick}
            role="tab"
            type="button"
          >
            {icon && className && <FontAwesomeIcon className={className} icon={icon} />}
            {icon && !className && <FontAwesomeIcon icon={icon} />}
            <span className="button-label">{categoriesName}</span>
          </button>
        </>
      )}
    </>
  );
};

BrowseNavTabComponent.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.shape(),
  dataCategory: PropTypes.number,
  handleClick: PropTypes.func,
  categoriesName: PropTypes.string,
  bucketId: PropTypes.number.isRequired,
};

BrowseNavTabComponent.defaultProps = {
  className: null,
  icon: null,
  isActive: null,
  dataCategory: null,
  categoriesName: null,
  handleClick: null,
};

export default BrowseNavTabComponent;
