/**
 * @param   {[String]} startLanguage  [Language identifier]
 * @param   {[String]} audioDirectory [Directory within public/audio that contains correct audio file]
 * @returns {[String]}                [File path prefix]
 */

export function generateAudioPathPrefix(startLanguage, audioDirectory) {
  if (
    startLanguage === 'en-GB' ||
    startLanguage === 'en-AU' ||
    startLanguage === 'en-NZ' ||
    startLanguage === 'en-IN'
  ) {
    return `/audio/${audioDirectory}/en-GB/`;
  }

  if (startLanguage === 'pt-BR') {
    return `/audio/${audioDirectory}/${startLanguage}/`;
  }

  return `/audio/${audioDirectory}/en-US/`;
}

export function updateLiveReaderInfo(msg, el) {
  document.getElementById(el).textContent = msg;

  return setTimeout(() => {
    document.getElementById(el).textContent = '';
    clearTimeout(this);
  }, 300);
}

export default {
  generateAudioPathPrefix,
  updateLiveReaderInfo,
};
