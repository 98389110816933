import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Howl, Howler } from 'howler';
import Pablo from '@britannica/pablito';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { PabloConstants } from './PabloConstants';
import PabloPage from './PabloPage';
import {
  calculateArrowPos,
  setupColorSwashes,
  animateCSS,
  setToolbarFocus,
  handleNoAnimationEnd,
  setCursorImage,
  createStickerbookEnabledColorConfig,
} from './PabloUtil';
import { Locales } from '../../constants';
import LiveReader from '../../Components/LiveReader/LiveReader';
import { updateLiveReaderInfo } from '../../Utils/audioUtilities';

class PabloPageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // reducedMotionMatchMedia is for cases where the animation
      // does not fire (examples: "reduce motion" setting turned on on Mac or
      // if a screen reader by default turns animations off)
      // In the case that the animation are turned off reducedMotionMatchMedia.matches
      // will be set to true and in this case a custom handler will be called (handleNoAnimationEnd)
      // which will make sure that anything needed to be run on animation end is run
      // https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
      reducedMotionMatchMedia: window.matchMedia('(prefers-reduced-motion)'),
      selectedBrush: PabloConstants.TOOL.PENCIL,
      toolbarSelection: PabloConstants.TOOL.PENCIL,
      selectedColor: PabloConstants.DEFAULT_SELECTED_COLOR,
      selectedStickerImage: '',
      selectedBackgroundImage: '',
      // Added in to accomodate new 'pattern' sticker logic
      selectedStickerImagePath: '',
      isColorBarVisible: false,
      isBackgroundBarVisible: false,
      isStickerBarVisible: false,
      isRightDrawerOpen: false,
      isLeftDrawerOpen: false,
      isAnimating: false,
      stickerbook: {},
      historyLength: 0,
      historyIndex: -1,
    };

    this.handleHistoryUpdate = this.handleHistoryUpdate.bind(this);
    this.redo = this.redo.bind(this);
    this.undo = this.undo.bind(this);
    this.clearCanvas = this.clearCanvas.bind(this);
    this.setupCanvas = this.setupCanvas.bind(this);
    this.setStateBrushSelection = this.setStateBrushSelection.bind(this);
    this.setStickerbookBrushSelection = this.setStickerbookBrushSelection.bind(this);
    this.setToolbarSelection = this.setToolbarSelection.bind(this);
    this.setColorSelection = this.setColorSelection.bind(this);
    this.setBackgroundSelection = this.setBackgroundSelection.bind(this);
    this.setStickerSelection = this.setStickerSelection.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.playSound = this.playSound.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.setArrowPosition = this.setArrowPosition.bind(this); // Set the drawer pointer arrow
    this.print = this.print.bind(this);
    /* Callbacks for animateCSS */
    this.animateCloseDrawerCallback = this.animateCloseDrawerCallback.bind(this);
    this.animateCloseAndOpenDrawerCallback = this.animateCloseAndOpenDrawerCallback.bind(this);
    this.setAnimateStateCallback = this.setAnimateStateCallback.bind(this);
    this.setStickerAnimateCallback = this.setStickerAnimateCallback.bind(this);
    this.setBackgroundAnimateCallback = this.setBackgroundAnimateCallback.bind(this);
    this.setColorAnimateCallback = this.setColorAnimateCallback.bind(this);
    /* Callbacks for animateCSS */

    this.$rightToolbarContainer = createRef();
    this.$leftToolbarContainer = createRef();
    this.$arrowRight = createRef();
    this.$colorsCont = createRef();
    this.$stickersCont = createRef();
    this.$backgroundsCont = createRef();
    this.$rightControlsCont = createRef();
    this.$leftControlsCont = createRef();
    this.$stickerbookContainer = createRef();
    this.$rightDrawerCont = createRef();
    this.$leftDrawerCont = createRef();
    this.$arrowLeft = createRef();

    this.defaultAnimationTimeout = null;
    this.rightToolbarContainerID = '#rightDrawerCont';
    this.hideDrawerClass = 'hideDrawer';
    this.leftDrawerContainerID = '#leftDrawerCont';
    this.delete = 'delete';
    this.active = 'active';
    this.introduction = null;

    // Automatically attempts to enable audio on mobile (iOS, Android, etc) devices and desktop Chrome/Safari.
    // https://github.com/goldfire/howler.js/
    Howler.autoUnlock = false;

    // Turned off voiceover for initial back to school release (8/2019) because there
    // was not enough time to add text to match voiceovers which is required
    // for accessibility - to revert to audio uncomment applicable lines with note
    // "Uncomment to turn audio back on"
  }

  componentDidMount() {
    const { selectedColor, selectedBrush } = this.state;
    const { pabloPageScreenReaderGreeting } = this.props;

    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(pabloPageScreenReaderGreeting, 'interactive-text');
    }, 450);

    // Initialize stickerbook
    const stickerbook = new Pablo({
      container: this.$stickerbookContainer.current,
      stickers: {
        // The available stickers that can be used
        enabled: PabloConstants.STICKER_IMAGE_PATHS,

        // Styling options for sticker sizing controls (optional)
        controls: {
          cornerColor: '',
          cornerSize: 0,
          hasBorders: false,
        },

        // Default positioning for stickers (provide whatever you need, the rest defaults to reasonable values)
        defaults: {
          x: 0, // If x and y defaults are not provided, you *must* provide them when calling placeSticker()
          y: 0,
          xScale: 1,
          yScale: 1,
          rotation: 0, // Degrees
        },
      },
      background: {
        enabled: PabloConstants.BACKGROUND_IMAGE_PATHS,
        default: PabloConstants.DEFAULT_SELECTED_BACKGROUND.PATH,
      },
      brush: {
        widths: PabloConstants.WIDTHS,
        enabled: PabloConstants.BRUSHES,
        colors: createStickerbookEnabledColorConfig(PabloConstants.ENABLED_COLORS),
      },
      mobileEnabled: true,
      useDefaultEventHandlers: true,
    });

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('pablito-history', this.handleHistoryUpdate);

    this.setupCanvas(); // Positions canvas between left and right toolbar

    setupColorSwashes(); // Sets up colors on color drawer

    // Set stickerboard initial state
    stickerbook.backgroundManager.setPositioning('fit-height');
    stickerbook.setColor(selectedColor);
    stickerbook.resize();
    // Set cursor when on canvas (dimensions must be below 128px)
    // eslint-disable-next-line no-underscore-dangle
    stickerbook._canvas.freeDrawingCursor = setCursorImage(selectedBrush);
    // Uncomment to revert to original sticker logic
    // stickerbook.enterStickerMode();

    document.getElementsByName(selectedBrush)[0].classList.add(this.active);

    // Set animation of default tool on page load
    this.defaultAnimationTimeout = setTimeout(() => {
      animateCSS('.defaultTool', 'tada', null);
    }, 1000);

    // Expose everything, so users can play around with it
    window.userStickerbook = stickerbook;

    this.setState(
      {
        stickerbook,
        selectedBackgroundImage: PabloConstants.DEFAULT_SELECTED_BACKGROUND.NAME,
        // Added in set default sticker selection to accomodate new "pattern" sticker logic
        selectedStickerImage: PabloConstants.DEFAULT_SELECTED_STICKER.NAME,
        selectedStickerImagePath: PabloConstants.DEFAULT_SELECTED_STICKER.PATH,
      },
      () => {
        this.setStickerbookBrushSelection(selectedBrush);
        this.setToolbarSelection(selectedBrush);
        // this.playSound(selectedBrush); // Creates an issue in Safari - restrictions on auto play
      }
    );
  }

  componentDidUpdate() {
    const { isRightDrawerOpen, isLeftDrawerOpen } = this.state;
    // Uncomment to turn audio back on
    // const { audio } = this.props;

    // If drawer is closed remove margin blocking canvas / if drawer is open add margin
    if (isRightDrawerOpen) {
      this.$rightControlsCont.current.classList.add('controlsMarginLeft');
    } else if (!isRightDrawerOpen) {
      this.$rightControlsCont.current.classList.remove('controlsMarginLeft');
    }

    if (isLeftDrawerOpen) {
      this.$leftControlsCont.current.classList.add('controlsMarginRight');
    } else if (!isLeftDrawerOpen) {
      this.$leftControlsCont.current.classList.remove('controlsMarginRight');
    }

    // Uncomment to turn audio back on
    // Howler.mute(!audio);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('pablito-history', this.handleHistoryUpdate);

    if (this.defaultAnimationTimeout) {
      clearTimeout(this.defaultAnimationTimeout);
    }

    this.setState({ stickerbook: {} });
  }

  setupCanvas() {
    // Calculate the width of the canvas (container is set by shell); width will be 100% of the container minus the width of the toolbars
    const RightControlsContDims = this.$rightControlsCont.current.getBoundingClientRect();
    const LeftControlContDims = this.$leftControlsCont.current.getBoundingClientRect();

    this.$stickerbookContainer.current.style.width = `calc(100% - ${RightControlsContDims.width +
      LeftControlContDims.width}px)`;
    this.$stickerbookContainer.current.style.left = `${LeftControlContDims.width}px`;
  }

  setArrowPosition() {
    // Sets the drawer's arrow position
    const { isStickerBarVisible, toolbarSelection, isBackgroundBarVisible, isColorBarVisible } = this.state;
    const $toolbarSelectionElement = document.getElementsByName(toolbarSelection)[0];
    const ToolbarSelectionElementDims = $toolbarSelectionElement.getBoundingClientRect();

    let arrowPos = {};

    if (isColorBarVisible) {
      const rightToolbarContainerDims = this.$rightToolbarContainer.current.getBoundingClientRect();
      const ArrowRightDims = this.$arrowRight.current.getBoundingClientRect();
      const colorsWidth = Number.parseFloat(window.getComputedStyle(this.$colorsCont.current).width.replace('px', ''));

      arrowPos = calculateArrowPos(
        colorsWidth,
        ArrowRightDims.height,
        rightToolbarContainerDims.top,
        ToolbarSelectionElementDims.bottom,
        ToolbarSelectionElementDims.height,
        'right'
      );

      this.$arrowRight.current.style.top = arrowPos.top;
      this.$arrowRight.current.style.left = arrowPos.left;
    } else if (isStickerBarVisible) {
      const stickersWidth = Number.parseFloat(
        window.getComputedStyle(this.$stickersCont.current).width.replace('px', '')
      );
      const ArrowRightDims = this.$arrowRight.current.getBoundingClientRect();
      const rightToolbarContainerDims = this.$rightToolbarContainer.current.getBoundingClientRect();

      arrowPos = calculateArrowPos(
        stickersWidth,
        ArrowRightDims.height,
        rightToolbarContainerDims.top,
        ToolbarSelectionElementDims.bottom,
        ToolbarSelectionElementDims.height,
        'right'
      );

      this.$arrowRight.current.style.top = arrowPos.top;
      this.$arrowRight.current.style.left = arrowPos.left;
    } else if (isBackgroundBarVisible) {
      const backgroundsWidth = Number.parseFloat(
        window.getComputedStyle(this.$backgroundsCont.current).width.replace('px', '')
      );
      const ArrowLeftDims = this.$arrowLeft.current.getBoundingClientRect();
      const leftToolbarContainerDims = this.$rightToolbarContainer.current.getBoundingClientRect();

      arrowPos = calculateArrowPos(
        backgroundsWidth,
        ArrowLeftDims.height,
        leftToolbarContainerDims.top,
        ToolbarSelectionElementDims.bottom,
        ToolbarSelectionElementDims.height,
        'left'
      );

      this.$arrowLeft.current.style.top = arrowPos.top;
      this.$arrowLeft.current.style.right = arrowPos.right;
    }
  }

  setStickerAnimateCallback() {
    // Callback for drawer animation once a sticker is selected
    this.setState(
      {
        isStickerBarVisible: false,
        isRightDrawerOpen: false,
        isAnimating: false,
      },
      () => {
        const { toolbarSelection } = this.state;

        setToolbarFocus(toolbarSelection);
      }
    );

    this.$rightDrawerCont.current.classList.add(this.hideDrawerClass);
  }

  setStickerSelection(stickerName, event) {
    // Set sticker selection in stickerbook class and state and animate drawer close

    // https://medium.com/@ian.mundy/async-event-handlers-in-react-a1590ed24399
    event.persist();

    // Uncomment to revert to original sticker logic
    // const { stickerbook } = this.state;

    let stickerPath = '';

    // Depending on target (button/icon) grab src accordingly
    if (event.target.id !== '') {
      if (event.target.lastChild.src) {
        stickerPath = event.target.lastChild.src;
      } else {
        // Target is the button
        const baseUri = event.target.baseURI;

        stickerPath = `${baseUri.replace('/create', '')}${event.target.dataset.src}`;
      }
    } else {
      stickerPath = event.target.src;
    }

    this.setState(
      {
        selectedStickerImage: stickerName,

        // Added in to accomodate new 'pattern' sticker logic
        selectedStickerImagePath: stickerPath,
      },
      () => {
        const { stickerbook, toolbarSelection, reducedMotionMatchMedia } = this.state;

        // Uncomment to revert to original sticker logic
        // stickerbook.setSticker(stickerPath);

        // Added in to accomodate new 'pattern' sticker logic
        this.setStickerbookBrushSelection(PabloConstants.TOOL.STICKER);

        // eslint-disable-next-line no-underscore-dangle
        stickerbook._canvas.freeDrawingCursor = setCursorImage(toolbarSelection);

        this.setState({ isAnimating: true }, () => {
          if (!reducedMotionMatchMedia.matches) {
            animateCSS(
              this.rightToolbarContainerID,
              PabloConstants.DRAWER_RIGHT_OUT_ANIMATION,
              this.setStickerAnimateCallback
            );
          } else {
            handleNoAnimationEnd(
              this.rightToolbarContainerID,
              PabloConstants.DRAWER_RIGHT_OUT_ANIMATION,
              this.setStickerAnimateCallback
            );
          }
        });
      }
    );
  }

  setBackgroundAnimateCallback(selectedBrush) {
    // Callback for drawer animation once a background is selected
    const { stickerbook } = this.state;

    this.setState(
      {
        isBackgroundBarVisible: false,
        isLeftDrawerOpen: false,
        isAnimating: false,
      },
      () => {
        setToolbarFocus(PabloConstants.TOOL.SET_BACKGROUND);
      }
    );

    this.$leftDrawerCont.current.classList.add(this.hideDrawerClass);

    this.setStickerbookBrushSelection(selectedBrush);

    // eslint-disable-next-line no-underscore-dangle
    stickerbook._canvas.freeDrawingCursor = setCursorImage(selectedBrush);

    this.setToolbarSelection(selectedBrush);

    // Removed to satisy KTWO-14
    // this.playSound(selectedBrush);
  }

  setBackgroundSelection(event) {
    // Set background selection in stickerbook instance and state and animate drawer close
    event.persist();

    const { stickerbook, selectedBrush, reducedMotionMatchMedia } = this.state;

    let backgroundPath = '';

    let selectedBackgroundImage = '';

    // If event.target is div containing img grab the name from the first child (img)
    // else if the className includes "sixteen-nine-ratio" grab the name from the parent
    // element (button) else grab the img's name
    if (event.target.className.includes('backgroundImageCont')) {
      selectedBackgroundImage = event.target.firstChild.name;
    } else if (event.target.className.includes('sixteen-nine-ratio')) {
      selectedBackgroundImage = event.target.parentElement.name;
    } else {
      selectedBackgroundImage = event.target.name;
    }

    this.setState(
      {
        selectedBackgroundImage,
      },
      () => {
        // Depending on target (button/icon/div) grab src and name accordingly
        if (event.target.id !== '') {
          if (event.target.lastChild.src) {
            backgroundPath = event.target.lastChild.src;
          } else {
            // Target is the button
            const baseUri = event.target.baseURI;

            backgroundPath = `${baseUri.replace('/create', '')}${event.target.dataset.src}`;
          }

          // If the event.target is the button and the id includes delete
          // set the background path to the default white background
          // else set the background path to the src of the img
          if (event.target.id.includes(this.delete)) {
            backgroundPath = PabloConstants.DEFAULT_SELECTED_BACKGROUND.PATH;
          }
        } else {
          backgroundPath = event.target.src;

          // If the className of the target includes "sixteen-nine-ratio"
          // set the background path to the default white background
          if (event.target.className.includes('sixteen-nine-ratio')) {
            backgroundPath = PabloConstants.DEFAULT_SELECTED_BACKGROUND.PATH;
          }
        }

        stickerbook.setBackground(backgroundPath);

        this.setState({ isAnimating: true }, () => {
          if (!reducedMotionMatchMedia.matches) {
            animateCSS(
              this.leftDrawerContainerID,
              PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
              this.setBackgroundAnimateCallback.bind(null, selectedBrush)
            );
          } else {
            handleNoAnimationEnd(
              this.leftDrawerContainerID,
              PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
              this.setBackgroundAnimateCallback.bind(null, selectedBrush)
            );
          }
        });
      }
    );
  }

  setColorAnimateCallback() {
    // Callback for drawer animation once a color is selected
    this.setState(
      {
        isColorBarVisible: false,
        isRightDrawerOpen: false,
        isAnimating: false,
      },
      () => {
        const { toolbarSelection } = this.state;

        setToolbarFocus(toolbarSelection);
      }
    );

    this.$rightDrawerCont.current.classList.add(this.hideDrawerClass);
  }

  setColorSelection(selectedColor) {
    // Set color selection in stickerbook instance and state and animate drawer close
    const { stickerbook, reducedMotionMatchMedia } = this.state;

    stickerbook.setColor(selectedColor);
    this.setState({ selectedColor, isAnimating: true }, () => {
      if (!reducedMotionMatchMedia.matches) {
        animateCSS(
          this.rightToolbarContainerID,
          PabloConstants.DRAWER_RIGHT_OUT_ANIMATION,
          this.setColorAnimateCallback
        );
      } else {
        handleNoAnimationEnd(
          this.rightToolbarContainerID,
          PabloConstants.DRAWER_RIGHT_OUT_ANIMATION,
          this.setColorAnimateCallback
        );
      }
    });
  }

  setStateBrushSelection(selectedBrush) {
    this.setState({
      selectedBrush,
    });
  }

  setStickerbookBrushSelection(selectedBrush) {
    // Set brush selection as well as the appropriate brush config if applicable in the stickerbook instance
    const { stickerbook, selectedStickerImagePath } = this.state;

    let brushConfig = {};

    if (selectedBrush === PabloConstants.TOOL.FILL) {
      brushConfig = { isAsync: true, stepsPerFrame: 100, partialFill: false };
    } else if (selectedBrush === PabloConstants.TOOL.PENCIL) {
      stickerbook.setBrushWidth(PabloConstants.BRUSH_WIDTHS.PENCIL);
    } else if (selectedBrush === PabloConstants.TOOL.MARKER) {
      stickerbook.setBrushWidth(PabloConstants.BRUSH_WIDTHS.MARKER);
    } else if (selectedBrush === PabloConstants.TOOL.ERASER) {
      stickerbook.setBrushWidth(PabloConstants.BRUSH_WIDTHS.ERASER);
    } else if (selectedBrush === PabloConstants.TOOL.STICKER) {
      stickerbook.setBrushWidth(PabloConstants.BRUSH_WIDTHS.PATTERN);
      brushConfig = {
        images: [`${selectedStickerImagePath}`],
      };
    } else if (selectedBrush === PabloConstants.TOOL.SPRAY) {
      stickerbook.setBrushWidth(PabloConstants.BRUSH_WIDTHS.SPRAY);
    }

    if (selectedBrush === PabloConstants.TOOL.MARKER) {
      stickerbook.setBrush(PabloConstants.TOOL.PENCIL);
    } else if (selectedBrush === PabloConstants.TOOL.SPRAY) {
      stickerbook.setBrush(PabloConstants.SPRAY_BRUSH_ALTERNATIVE_STYLE);
    } else {
      stickerbook.setBrush(selectedBrush, brushConfig);
    }
  }

  setToolbarSelection(toolbarSelection) {
    // Set the toolbar selection in state; this allows for conditioning between brushes and other tools which may have different functionality
    const { stickerbook } = this.state;

    this.setState(
      {
        toolbarSelection,
      },
      () => {
        if (toolbarSelection !== PabloConstants.TOOL.SET_BACKGROUND) {
          if (toolbarSelection === PabloConstants.TOOL.STICKER) {
            // eslint-disable-next-line no-underscore-dangle
            stickerbook._canvas.freeDrawingCursor = setCursorImage(toolbarSelection);
          } else {
            // eslint-disable-next-line no-underscore-dangle
            stickerbook._canvas.freeDrawingCursor = setCursorImage(toolbarSelection);
          }
        }
      }
    );
  }

  setAnimateStateCallback() {
    this.setState({
      isAnimating: false,
    });
  }

  animateCloseDrawerCallback(toolName, toolbarSelection) {
    // Callback for close drawer animation
    const $backgroundButton = document.getElementById(PabloConstants.TOOL.SET_BACKGROUND);
    const $printButton = document.getElementById(PabloConstants.TOOL.PRINT);
    const { selectedBrush, stickerbook, isRightDrawerOpen } = this.state;

    // Keep logic pertaining to animation end contained within applicable animate callback
    // to avoid any asyncronous issues

    // Uncomment to revert to original sticker logic
    /* if (toolName === PabloConstants.TOOL.STICKER) {
      $stickerButton.classList.remove(this.active);
    } else */ if (
      toolName === PabloConstants.TOOL.SET_BACKGROUND
    ) {
      $backgroundButton.classList.remove(this.active);
    } else if (toolName === PabloConstants.TOOL.PRINT) {
      $printButton.classList.remove(this.active);
    }

    if (isRightDrawerOpen) {
      this.$rightDrawerCont.current.classList.add(this.hideDrawerClass);
    } else {
      this.$leftDrawerCont.current.classList.add(this.hideDrawerClass);
    }

    this.setState(
      {
        isColorBarVisible: false,
        isBackgroundBarVisible: false,
        isStickerBarVisible: false,
        isRightDrawerOpen: false,
        isLeftDrawerOpen: false,
        isAnimating: false,
      },
      () => {
        if (
          toolName === PabloConstants.TOOL.UNDO ||
          toolName === PabloConstants.TOOL.REDO ||
          toolName === PabloConstants.TOOL.ERASER ||
          toolName === PabloConstants.TOOL.PRINT ||
          toolName === PabloConstants.TOOL.CLEAR
        ) {
          setToolbarFocus(toolName);
        } else {
          setToolbarFocus(toolbarSelection);
        }

        if (
          toolName === toolbarSelection &&
          // Uncomment to revert to original sticker logic
          toolName === PabloConstants.TOOL.SET_BACKGROUND /* || toolName === PabloConstants.TOOL.STICKER */
        ) {
          this.setStickerbookBrushSelection(selectedBrush);

          // eslint-disable-next-line no-underscore-dangle
          stickerbook._canvas.freeDrawingCursor = setCursorImage(selectedBrush);

          this.setToolbarSelection(selectedBrush);

          // Removed to satisy KTWO-14
          // this.playSound(selectedBrush);
        }
      }
    );
  }

  animateCloseAndOpenDrawerCallback(toolName, toolbarSelection) {
    // Callback for close and open drawer animation
    const { isRightDrawerOpen, reducedMotionMatchMedia } = this.state;

    if (isRightDrawerOpen) {
      this.$rightDrawerCont.current.classList.add(this.hideDrawerClass);
    } else {
      this.$leftDrawerCont.current.classList.add(this.hideDrawerClass);
    }

    this.setState(
      {
        isColorBarVisible: false,
        isBackgroundBarVisible: false,
        isStickerBarVisible: false,
        isLeftDrawerOpen: false,
        isRightDrawerOpen: false,
      },
      () => {
        if (
          toolName === PabloConstants.TOOL.FILL ||
          toolName === PabloConstants.TOOL.SPRAY ||
          toolName === PabloConstants.TOOL.PENCIL ||
          (toolName === PabloConstants.TOOL.MARKER && toolName !== toolbarSelection)
        ) {
          this.setState(
            {
              isColorBarVisible: true,
              isRightDrawerOpen: true,
            },
            () => {
              this.$rightDrawerCont.current.classList.remove(this.hideDrawerClass);
              this.$colorsCont.current.focus();
              this.setArrowPosition();

              if (!reducedMotionMatchMedia.matches) {
                animateCSS(
                  this.rightToolbarContainerID,
                  PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              } else {
                handleNoAnimationEnd(
                  this.rightToolbarContainerID,
                  PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              }
            }
          );
        } else if (toolName === PabloConstants.TOOL.STICKER && toolName !== toolbarSelection) {
          this.setState(
            {
              isStickerBarVisible: true,
              isRightDrawerOpen: true,
            },
            () => {
              this.$rightDrawerCont.current.classList.remove(this.hideDrawerClass);
              this.$stickersCont.current.focus();
              this.setArrowPosition();

              if (!reducedMotionMatchMedia.matches) {
                animateCSS(
                  this.rightToolbarContainerID,
                  PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              } else {
                handleNoAnimationEnd(
                  this.rightToolbarContainerID,
                  PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              }
            }
          );
        } else if (toolName === PabloConstants.TOOL.SET_BACKGROUND && toolName !== toolbarSelection) {
          this.setState(
            {
              isBackgroundBarVisible: true,
              isLeftDrawerOpen: true,
            },
            () => {
              this.$leftDrawerCont.current.classList.remove(this.hideDrawerClass);
              this.$backgroundsCont.current.focus();
              this.setArrowPosition();

              if (!reducedMotionMatchMedia.matches) {
                animateCSS(
                  this.leftDrawerContainerID,
                  PabloConstants.DRAWER_LEFT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              } else {
                handleNoAnimationEnd(
                  this.leftDrawerContainerID,
                  PabloConstants.DRAWER_LEFT_IN_ANIMATION,
                  this.setAnimateStateCallback
                );
              }
            }
          );
        }
      }
    );
  }

  undo() {
    // Undo last canvas addition
    const { stickerbook } = this.state;

    stickerbook.undo();
  }

  redo() {
    // Redo last canvas removal
    const { stickerbook } = this.state;

    stickerbook.redo();
  }

  handleResize() {
    this.setupCanvas();
    this.setArrowPosition();
  }

  playSound(selectedVoiceoverName) {
    // Toggle Sound status to playing and set active click sound in state
    const { startLanguage } = this.props;

    let replaceFileFormat = '';

    let audioPathPrefix = '';

    switch (startLanguage) {
      case Locales.ENGLISH_US:
        replaceFileFormat = PabloConstants.SOUND_REPLACE_FILE_FORMATS.EN_US;
        audioPathPrefix = PabloConstants.SOUND_PATH_PREFIXES.EN_US;
        break;
      case Locales.PORTUGUESE_BRAZIL:
        replaceFileFormat = PabloConstants.SOUND_REPLACE_FILE_FORMATS.PT_BR;
        audioPathPrefix = PabloConstants.SOUND_PATH_PREFIXES.PT_BR;
        break;
      default:
        replaceFileFormat = PabloConstants.SOUND_REPLACE_FILE_FORMATS.EN_US;
        audioPathPrefix = PabloConstants.SOUND_PATH_PREFIXES.EN_US;
        break;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const voiceoverFile of PabloConstants.SOUNDS.VOICEOVER_FILES) {
      const voiceoverName = voiceoverFile.replace(replaceFileFormat, '');

      if (voiceoverName === selectedVoiceoverName) {
        const sound = new Howl({ src: [`${audioPathPrefix}${voiceoverFile}`], html5: true });

        sound.play();
        break;
      }
    }
  }

  toggleDrawer(toolName) {
    // Hide and show drawer with animation with conditioning
    const { toolbarSelection, isLeftDrawerOpen, isRightDrawerOpen, reducedMotionMatchMedia } = this.state;
    // Uncomment to revert to original sticker logic
    // const $stickerButton = document.getElementById(PabloConstants.TOOL.STICKER);

    if (isRightDrawerOpen || isLeftDrawerOpen) {
      this.setState(
        {
          isAnimating: true,
        },
        () => {
          if (
            toolName === toolbarSelection ||
            toolName === PabloConstants.TOOL.ERASER ||
            toolName === PabloConstants.TOOL.PRINT ||
            toolName === PabloConstants.TOOL.UNDO ||
            toolName === PabloConstants.TOOL.REDO ||
            toolName === PabloConstants.TOOL.CLEAR
          ) {
            // If the drawer is open and the selected tool is the same as the current active tool, eraser, print, undo, redo, or clear close the drawer
            if (!reducedMotionMatchMedia.matches) {
              animateCSS(
                isRightDrawerOpen ? this.rightToolbarContainerID : this.leftDrawerContainerID,
                isRightDrawerOpen
                  ? PabloConstants.DRAWER_RIGHT_OUT_ANIMATION
                  : PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
                this.animateCloseDrawerCallback.bind(null, toolName, toolbarSelection)
              );
            } else {
              handleNoAnimationEnd(
                isRightDrawerOpen ? this.rightToolbarContainerID : this.leftDrawerContainerID,
                isRightDrawerOpen
                  ? PabloConstants.DRAWER_RIGHT_OUT_ANIMATION
                  : PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
                this.animateCloseDrawerCallback.bind(null, toolName, toolbarSelection)
              );
            }
          } else {
            // Else the selected tool has a drawer associated with it so close the current drawer that is open, wait for the drawer animation to conclude and then open the drawer for the current selected tool
            // eslint-disable-next-line no-lonely-if
            if (!reducedMotionMatchMedia.matches) {
              animateCSS(
                isRightDrawerOpen ? this.rightToolbarContainerID : this.leftDrawerContainerID,
                isRightDrawerOpen
                  ? PabloConstants.DRAWER_RIGHT_OUT_ANIMATION
                  : PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
                this.animateCloseAndOpenDrawerCallback.bind(null, toolName, toolbarSelection)
              );
            } else {
              handleNoAnimationEnd(
                isRightDrawerOpen ? this.rightToolbarContainerID : this.leftDrawerContainerID,
                isRightDrawerOpen
                  ? PabloConstants.DRAWER_RIGHT_OUT_ANIMATION
                  : PabloConstants.DRAWER_LEFT_OUT_ANIMATION,
                this.animateCloseAndOpenDrawerCallback.bind(null, toolName, toolbarSelection)
              );
            }
          }
        }
      );
    } else if (!isLeftDrawerOpen && !isRightDrawerOpen) {
      // If the drawers are not open, open the applicable drawer
      if (
        toolName === PabloConstants.TOOL.FILL ||
        toolName === PabloConstants.TOOL.SPRAY ||
        toolName === PabloConstants.TOOL.PENCIL ||
        toolName === PabloConstants.TOOL.MARKER
      ) {
        this.setState(
          {
            isColorBarVisible: true,
            isRightDrawerOpen: true,
            isAnimating: true,
          },
          () => {
            this.$rightDrawerCont.current.classList.remove(this.hideDrawerClass);
            this.$colorsCont.current.focus();
            this.setArrowPosition();
          }
        );

        if (!reducedMotionMatchMedia.matches) {
          animateCSS(
            this.rightToolbarContainerID,
            PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
            this.setAnimateStateCallback
          );
        } else {
          handleNoAnimationEnd(
            this.rightToolbarContainerID,
            PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
            this.setAnimateStateCallback
          );
        }
      } else if (toolName === PabloConstants.TOOL.STICKER) {
        this.setState(
          {
            isStickerBarVisible: true,
            isRightDrawerOpen: true,
            isAnimating: true,
          },
          () => {
            this.$rightDrawerCont.current.classList.remove(this.hideDrawerClass);
            this.$stickersCont.current.focus();
            this.setArrowPosition();
          }
        );

        if (!reducedMotionMatchMedia.matches) {
          animateCSS(
            this.rightToolbarContainerID,
            PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
            this.setAnimateStateCallback
          );
        } else {
          handleNoAnimationEnd(
            this.rightToolbarContainerID,
            PabloConstants.DRAWER_RIGHT_IN_ANIMATION,
            this.setAnimateStateCallback
          );
        }
      } else if (toolName === PabloConstants.TOOL.SET_BACKGROUND) {
        this.setState(
          {
            isBackgroundBarVisible: true,
            isLeftDrawerOpen: true,
            isAnimating: true,
          },
          () => {
            this.$leftDrawerCont.current.classList.remove(this.hideDrawerClass);
            this.$backgroundsCont.current.focus();
            this.setArrowPosition();
          }
        );

        if (!reducedMotionMatchMedia.matches) {
          animateCSS(this.leftDrawerContainerID, PabloConstants.DRAWER_LEFT_IN_ANIMATION, this.setAnimateStateCallback);
        } else {
          handleNoAnimationEnd(
            this.leftDrawerContainerID,
            PabloConstants.DRAWER_LEFT_IN_ANIMATION,
            this.setAnimateStateCallback
          );
        }
      }
    }
  }

  print() {
    // Add print class to body (hides elements on print) and call window.print()
    // Reset the active tool to the one selected before print
    const { selectedBrush, stickerbook, toolbarSelection } = this.state;
    const $body = document.getElementById('body');
    const $printBtn = document.getElementById('print');
    const $selectedBrushElem = document.getElementById(selectedBrush);

    $printBtn.classList.remove(this.active);
    $body.classList.remove('print');
    $selectedBrushElem.classList.add(this.active);

    this.setStickerbookBrushSelection(selectedBrush);

    setToolbarFocus(toolbarSelection);

    // eslint-disable-next-line no-underscore-dangle
    stickerbook._canvas.freeDrawingCursor = setCursorImage(selectedBrush);

    this.setToolbarSelection(selectedBrush);

    // Removed to satisy KTWO-14
    // this.playSound(selectedBrush);

    $body.classList.add('print');
    window.print();
  }

  clearCanvas() {
    const { stickerbook, historyIndex } = this.state;
    const { clearCanvasSrAlert } = this.props;

    // Condition added to fix KTWO-224
    if (historyIndex !== -1) {
      stickerbook.clear();
      updateLiveReaderInfo(clearCanvasSrAlert, 'interactive-text');
    }
  }

  handleHistoryUpdate() {
    const { stickerbook } = this.state;

    this.setState({
      historyLength: stickerbook.historyManager.history.length,
      historyIndex: stickerbook.historyManager.historyIndex,
    });
  }

  render() {
    const { pageTitleCreate, colorDrawerLabel, stickerDrawerLabel, backgroundDrawerLabel } = this.props;
    const {
      isColorBarVisible,
      isStickerBarVisible,
      isBackgroundBarVisible,
      toolbarSelection,
      isAnimating,
      stickerbook,
      selectedBrush,
      selectedColor,
      selectedStickerImagePath,
      selectedStickerImage,
      selectedBackgroundImage,
      historyLength,
      historyIndex,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{pageTitleCreate}</title>
        </Helmet>
        <PabloPage
          undo={this.undo}
          redo={this.redo}
          $rightToolbarContainer={this.$rightToolbarContainer}
          $leftToolbarContainer={this.$leftToolbarContainer}
          $arrowRight={this.$arrowRight}
          $colorsCont={this.$colorsCont}
          $stickersCont={this.$stickersCont}
          $backgroundsCont={this.$backgroundsCont}
          $rightControlsCont={this.$rightControlsCont}
          $leftControlsCont={this.$leftControlsCont}
          $stickerbookContainer={this.$stickerbookContainer}
          $rightDrawerCont={this.$rightDrawerCont}
          $leftDrawerCont={this.$leftDrawerCont}
          $arrowLeft={this.$arrowLeft}
          toggleDrawer={this.toggleDrawer}
          playSound={this.playSound}
          setToolbarSelection={this.setToolbarSelection}
          setStickerSelection={this.setStickerSelection}
          setColorSelection={this.setColorSelection}
          setStateBrushSelection={this.setStateBrushSelection}
          print={this.print}
          setStickerbookBrushSelection={this.setStickerbookBrushSelection}
          setBackgroundSelection={this.setBackgroundSelection}
          isColorBarVisible={isColorBarVisible}
          isStickerBarVisible={isStickerBarVisible}
          isBackgroundBarVisible={isBackgroundBarVisible}
          toolbarSelection={toolbarSelection}
          isAnimating={isAnimating}
          stickerbook={stickerbook}
          selectedBrush={selectedBrush}
          selectedColor={selectedColor}
          selectedStickerImagePath={selectedStickerImagePath}
          selectedStickerImage={selectedStickerImage}
          selectedBackgroundImage={selectedBackgroundImage}
          colorDrawerLabel={colorDrawerLabel}
          stickerDrawerLabel={stickerDrawerLabel}
          backgroundDrawerLabel={backgroundDrawerLabel}
          clearCanvas={this.clearCanvas}
          historyLength={historyLength}
          historyIndex={historyIndex}
        />
        <LiveReader classHolder="create-page" />
      </>
    );
  }
}

PabloPageContainer.propTypes = {
  pageTitleCreate: PropTypes.string.isRequired,
  startLanguage: PropTypes.string.isRequired,
  colorDrawerLabel: PropTypes.string.isRequired,
  stickerDrawerLabel: PropTypes.string.isRequired,
  backgroundDrawerLabel: PropTypes.string.isRequired,
  pabloPageScreenReaderGreeting: PropTypes.string.isRequired,
  clearCanvasSrAlert: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pageTitleCreate: state.languageWidget.translation['shell-page-title-create'],
  // Uncomment to turn audio back on
  // audio: state.audioWidget.audio,
  startLanguage: state.languageWidget.startLanguage,
  colorDrawerLabel: state.languageWidget.translation['pablo-sr-color-drawer'],
  stickerDrawerLabel: state.languageWidget.translation['pablo-sr-sticker-drawer'],
  backgroundDrawerLabel: state.languageWidget.translation['pablo-sr-background-drawer'],
  pabloPageScreenReaderGreeting: state.languageWidget.translation['pablo-screen-reader-greeting'],
  clearCanvasSrAlert: state.languageWidget.translation['pablo-sr-clear-canvas-alert'],
});

export default connect(mapStateToProps)(PabloPageContainer);
