import { TOGGLE_AIRDROPANIMATION } from '../actionTypes';

const initialState = { navigationAirdropAnimation: false };

const animationManager = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AIRDROPANIMATION: {
      return { ...state, navigationAirdropAnimation: !state.navigationAirdropAnimation };
    }

    default: {
      return state;
    }
  }
};

export default animationManager;
