import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutePath, RouteHelpers } from '../../constants';
import BooksPageBrowseContainer from './BooksPageBrowseContainer';
import BooksComponent from './BooksPageComponents/BooksComponent';
import { getLocalesByBucketIdAndRouteId } from '../../Utils/browseUtilities';

const BooksPageRoutes = () => {
  // NEED TO UPDATE WITH NEW MARKET
  return (
    <Switch>
      <PrivateRoute exact path={`${RoutePath.READ}/0001`} gamePath="bdl-animals" />
      <PrivateRoute exact path={`${RoutePath.READ}/0002`} gamePath="bdl-colors" />
      <PrivateRoute exact path={`${RoutePath.READ}/0012`} gamePath="bdl-me_and_you" />
      <PrivateRoute exact path={`${RoutePath.READ}/0006`} gamePath="bdl-people" />
      <PrivateRoute exact path={`${RoutePath.READ}/0013`} gamePath="bdl-shapes" />
      <PrivateRoute exact path={`${RoutePath.READ}/0021`} gamePath="bdl-shapes_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0022`} gamePath="bdl-animals_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0023`} gamePath="bdl-colors_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0024`} gamePath="bdl-me_and_you_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0025`} gamePath="bdl-people_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0014`} gamePath="local-adivinhas_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0015`} gamePath="local-parlendas_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0016`} gamePath="local-contos_e_lendas_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0017`} gamePath="local-cantigas_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0018`} gamePath="local-trava_linguas_portuguese" />
      <PrivateRoute exact path={`${RoutePath.READ}/0026`} gamePath="bdl-animals_en-gb" />
      <PrivateRoute exact path={`${RoutePath.READ}/0027`} gamePath="bdl-colors_en-gb" />
      <PrivateRoute exact path={`${RoutePath.READ}/0028`} gamePath="bdl-me_and_you_en-gb" />
      <PrivateRoute exact path={`${RoutePath.READ}/0029`} gamePath="bdl-people_en-gb" />
      <PrivateRoute exact path={`${RoutePath.READ}/0030`} gamePath="bdl-shapes_en-gb" />

      <Route exact path={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} component={BooksPageBrowseContainer} />
      <Route render={() => <Redirect to={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} />} />
    </Switch>
  );
};

export default BooksPageRoutes;

function PrivateRoute(params) {
  return (
    <Route
      render={props =>
        authenticate(window.sessionStorage.getItem('_eb_language'), params.path) !== -1 ? (
          <BooksComponent {...props} gamePath={params.gamePath} />
        ) : (
          <Redirect to={`${RoutePath.READ}/${RouteHelpers.BROWSE}`} />
        )
      }
    />
  );
}

function authenticate(language, path) {
  return getLocalesByBucketIdAndRouteId(1, path.split('/').pop()).indexOf(language);
}
