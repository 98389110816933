import { SET_ACCOUNT_ID, SET_HOSTNAME_OVERRIDE, SET_OVERRIDE_DOMAIN } from '../actionTypes';

export const setAccountId = accountId => ({
  type: SET_ACCOUNT_ID,
  accountId,
});

export const setHostnameOverride = hostnameOverride => ({
  type: SET_HOSTNAME_OVERRIDE,
  hostnameOverride,
});

export const setOverrideDomain = overrideDomain => ({
  type: SET_OVERRIDE_DOMAIN,
  overrideDomain,
});

export default { setAccountId, setHostnameOverride, setOverrideDomain };
