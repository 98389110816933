import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withLocalize } from 'react-localize-redux';
import ContentApi from '../../Services/ContentApi';
import { Endpoint } from '../../constants';
import { updateLiveReaderInfo } from '../../Utils/audioUtilities';
import LiveReader from '../../Components/LiveReader/LiveReader';

import {
  calculateBiomeState,
  convertToCamelCase,
  convertIdToAssembly,
  removeToggle,
  getBiomeSotlightAssemblyIdByBiomeAndLocale,
} from './MercatorUtilities';
import MercatorPage from './MercatorPage';

export class MercatorPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPins: false,
      modalIsOpen: false,
      currentBiome: null,
      assembly: null,
      biomeThumbnail: null, // The thumbnail image that goes in the biome spotlight container
      biomeThumbnailAltText: null, // The alt text for the thumbnail image.
      activeBiomeName: null, // The name of the currently active biome.
      // I should have kept this stat flat, but I decided to get cute with it
      // it only adds to complexity, don't do this.
      biomes: {
        // Which biomes are currently being displayed in the SVG
        display: {
          tundra: true,
          desert: true,
          taiga: true,
          temperateForest: true,
          tropicalForest: true,
          grassland: true,
        },
        // Whether the biome spotlight thumbnail image has been clicked
        // yet or not.
        spotlight: {
          tundra: false,
          desert: false,
          taiga: false,
          temperateForest: false,
          tropicalForest: false,
          grassland: false,
        },
      },
    };

    this.textTimeout = null;
    this.$biomeSpotlight = document.getElementsByClassName('biome-spotlight-feature--btn');
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleBiomeClick = this.handleBiomeClick.bind(this);
    this.handleSpotlightClick = this.handleSpotlightClick.bind(this);
    this.getDataClickBind = this.getDataClickBind.bind(this);
  }

  componentDidMount() {
    this.bindElements();
  }

  componentWillUnmount() {
    const $desertBoxes = document.querySelector('#desert_boxes');
    const $tundraBoxes = document.querySelector('#tundra_boxes');
    const $taigaBoxes = document.querySelector('#taiga_boxes');
    const $tempForestBoxes = document.querySelector('#temp_forest_boxes');
    const $tropForestBoxes = document.querySelector('#trop_forest_boxes');
    const $grasslandBoxes = document.querySelector('#grassland_boxes');
    const clickHandler = e => this.getDataClickBind(e.target.id, e);

    $desertBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $tundraBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $taigaBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $tempForestBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $tropForestBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $grasslandBoxes.removeEventListener('click', clickHandler, { bubbles: true });
    $desertBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
    $tundraBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
    $taigaBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
    $tempForestBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
    $tropForestBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
    $grasslandBoxes.removeEventListener('keydown', clickHandler, { bubbles: true });
  }

  /* Shows or hides a biome based on the button that was clicked */

  getDataClickBind(target, e) {
    // Covering for ADA compliance to act more like buttons
    // Enter and Space

    if (e.type === 'keydown' && e.keyCode !== 13 && e.keyCode !== 32) {
      return;
    }

    const assembly = convertIdToAssembly(target, sessionStorage.getItem('_eb_language'));

    this.setState(
      {
        modalIsOpen: true,
      },
      () => {
        ContentApi.fetchAssemblyByAssemblyId(assembly).then(response => {
          this.setState({
            assembly: response,
          });
        });
      }
    );
  }

  calculateBiomes(biome) {
    this.setState(state => ({
      biomes: {
        ...state.biomes,
        display: calculateBiomeState(biome),
      },
    }));
  }

  handleSpotlightClick(e) {
    const normalizedBiome = e.currentTarget.getAttribute('data-biome');

    this.setState(
      state => ({
        modalIsOpen: true,
        biomes: {
          display: {
            ...state.biomes.display,
          },
          spotlight: {
            ...state.biomes.spotlight,
            [normalizedBiome]: true,
          },
        },
      }),
      () => {
        ContentApi.fetchAssemblyByAssemblyId(
          getBiomeSotlightAssemblyIdByBiomeAndLocale(normalizedBiome, sessionStorage.getItem('_eb_language'))
        ).then(response => {
          this.setState({
            assembly: response,
          });
        });
      }
    );
  }

  handleBiomeClick(e) {
    const { resetMapText } = this.props;

    if (e.currentTarget.id !== 'earth-toggle') {
      document.querySelector('.biome-spotlight-feature').classList.remove('hide');

      const toggleRemoved = removeToggle(e.currentTarget.id);

      this.setState(
        state => ({
          showPins: true,
          currentBiome: convertToCamelCase(toggleRemoved),
          biomes: {
            ...state.biomes,
            display: calculateBiomeState(convertToCamelCase(toggleRemoved)),
          },
          biomeThumbnail: null,
        }),
        () => {
          const { currentBiome } = this.state;
          const featureSpotlight = document.getElementsByClassName(`biome-spotlight-feature--btn ${currentBiome}`);

          featureSpotlight[0].focus();
        }
      );
      // Figure out which biome was clicked on.

      const clicked = document.querySelector(`#${e.currentTarget.id} .button-label`);

      // This clears out the video thumbnail so the last active one isn't showing when
      // a new biome is selected. The rest of the function should probably be in the callback,
      // but I have yet to run into any timing issues regarding this.

      // We don't load anything if the the 'Reset' button is clicked.
      ContentApi.fetchAssemblyByAssemblyId(
        // TODO: change the locale parameter to not be hard coded once we have english assemblies.
        // Get the assembly information for the biome that was clicked. This should probably be cached,
        // but that is coming automagically in fetch/react so it didn't seem worth the complexity.
        getBiomeSotlightAssemblyIdByBiomeAndLocale(
          convertToCamelCase(toggleRemoved),
          sessionStorage.getItem('_eb_language')
        )
      ).then(response => {
        this.setState({
          biomeThumbnail: `${Endpoint.CDN_URL}${response.video.videoPoster.filename}`,
          biomeThumbnailAltText: response.caption || 'A video thumbnail of the biome video.',
          activeBiomeName: clicked.innerHTML,
        });
      });
    } else {
      updateLiveReaderInfo(resetMapText, 'interactive-text');
      document.getElementsByClassName('biome-spotlight-feature')[0].classList.add('hide');
      this.setState(state => ({
        biomes: {
          ...state.biomes,
          display: calculateBiomeState(null),
        },
        showPins: false,
      }));
    }

    const $buttons = document.getElementsByClassName('biome-toggle');

    // Reove all the active classes from the biome buttons
    for (let i = 0; i < $buttons.length; i += 1) {
      $buttons[i].classList.remove('active');
    }

    // Add the active class to the one that was clicked.
    e.currentTarget.classList.add('active');

    // Animate in the biome spotlight.
    document.getElementsByClassName('biome-spotlight-feature')[0].classList.add('animated');
  }

  // Where all the event binding is done.
  bindElements() {
    // All of the pin event handlers have to be handled manually
    const $desertBoxes = document.querySelector('#desert_boxes');
    const $tundraBoxes = document.querySelector('#tundra_boxes');
    const $taigaBoxes = document.querySelector('#taiga_boxes');
    const $tempForestBoxes = document.querySelector('#temp_forest_boxes');
    const $tropForestBoxes = document.querySelector('#trop_forest_boxes');
    const $grasslandBoxes = document.querySelector('#grassland_boxes');
    const clickHandler = e => this.getDataClickBind(e.target.id, e);

    $desertBoxes.addEventListener('click', clickHandler, { bubbles: true });
    $tundraBoxes.addEventListener('click', clickHandler, { bubbles: true });
    $taigaBoxes.addEventListener('click', clickHandler, { bubbles: true });
    $tempForestBoxes.addEventListener('click', clickHandler, { bubbles: true });
    $tropForestBoxes.addEventListener('click', clickHandler, { bubbles: true });
    $grasslandBoxes.addEventListener('click', clickHandler, { bubbles: true });

    $desertBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
    $tundraBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
    $taigaBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
    $tempForestBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
    $tropForestBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
    $grasslandBoxes.addEventListener('keydown', clickHandler, { bubbles: true });
  }

  handleCloseModal() {
    this.setState({
      modalIsOpen: false,
      assembly: null,
    });
  }

  render() {
    const {
      modalIsOpen,
      showPins,
      currentBiome,
      assembly,
      biomes,
      biomeThumbnail,
      biomeThumbnailAltText,
      activeBiomeName,
    } = this.state;

    const { pageTitleExplore, mercatorStringAriaLabelSpotlightMessage } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{pageTitleExplore}</title>
        </Helmet>
        <MercatorPage
          handleCloseModal={this.handleCloseModal}
          assembly={assembly}
          biomes={biomes}
          modalIsOpen={modalIsOpen}
          showPins={showPins}
          currentBiome={currentBiome}
          biomeThumbnail={biomeThumbnail}
          biomeThumbnailAltText={biomeThumbnailAltText}
          activeBiomeName={activeBiomeName}
          handleBiomeClick={this.handleBiomeClick}
          handleSpotlightClick={this.handleSpotlightClick}
          spotlightVideoAriaLabel={`${mercatorStringAriaLabelSpotlightMessage}`}
        />
        <LiveReader classHolder="explore-page" />
      </Fragment>
    );
  }
}

MercatorPageContainer.propTypes = {
  pageTitleExplore: PropTypes.string.isRequired,
  resetMapText: PropTypes.string.isRequired,
  mercatorStringAriaLabelSpotlightMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pageTitleExplore: state.languageWidget.translation['shell-page-title-explore'],
  resetMapText: state.languageWidget.translation['mercator-string-reset-action'],
  mercatorStringAriaLabelSpotlightMessage:
    state.languageWidget.translation['mercator-string-aria-label-spotlight-message'],
});

export default withLocalize(connect(mapStateToProps)(MercatorPageContainer));
