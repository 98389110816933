import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { Endpoint } from '../../constants';
import './BrowseListTileComponent.scss';

const BrowseListTileComponent = props => {
  const { title, route, topic, imagePath, imagePathAltText, classname } = props;

  return (
    <NavLink className={cx('browse-list-tile-component', classname)} to={route}>
      <div className="browse-list-tile-component--card">
        <div className="browse-list-tile-component--card--image">
          <img
            alt={imagePathAltText}
            height="182"
            width="150"
            src={`${Endpoint.CDN_URL}/${imagePath}`}
            aria-hidden="true"
          />
        </div>
        <div className="browse-list-tile-component--card--bottom">
          <div>{title}</div>
          {topic && <small>{topic}</small>}
        </div>
      </div>
    </NavLink>
  );
};

BrowseListTileComponent.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  topic: PropTypes.string,
  imagePath: PropTypes.string,
  imagePathAltText: PropTypes.string,
  classname: PropTypes.string,
};

BrowseListTileComponent.defaultProps = {
  title: null,
  route: null,
  topic: null,
  imagePath: null,
  imagePathAltText: null,
  classname: null,
};

export default BrowseListTileComponent;
