import { TOGGLE_LANGUAGE, SWITCH_LANGUAGE } from '../actionTypes';

const toggleLanguage = () => ({
  type: TOGGLE_LANGUAGE,
});

const setActiveLanguage = () => ({
  type: SWITCH_LANGUAGE,
});

export { toggleLanguage, setActiveLanguage };
