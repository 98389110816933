import { getLanguageData } from '../../Utils/localeUtilities';

const languageJson = getLanguageData();

const PlayManifest = [
  {
    id: '0001',
    title: languageJson['0001-play-title'],
    route: '0001',
    topic: languageJson['0001-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [0, 2, 8, 10, 14, 17, 20],
    imagePath: languageJson['0001-play-image-path'],
    imagePathAltText: languageJson['0001-play-text-image-alt'],
  },
  {
    id: '0002',
    title: languageJson['0002-play-title'],
    route: '0002',
    topic: languageJson['0002-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [0, 2, 8, 10, 14, 17, 20],
    imagePath: languageJson['0002-play-image-path'],
    imagePathAltText: languageJson['0002-play-text-image-alt'],
  },
  {
    id: '0003',
    title: languageJson['0003-play-title'],
    route: '0003',
    topic: languageJson['0003-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [0, 2, 8, 10, 14, 17, 20],
    imagePath: languageJson['0003-play-image-path'],
    imagePathAltText: languageJson['0003-play-text-image-alt'],
  },
  {
    id: '0004',
    title: languageJson['0004-play-title'],
    route: '0004',
    topic: languageJson['0004-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [0, 2, 8, 10, 14, 17, 20],
    imagePath: languageJson['0004-play-image-path'],
    imagePathAltText: languageJson['0004-play-text-image-alt'],
  },
  {
    id: '0005',
    title: languageJson['0005-play-title'],
    route: '0005',
    topic: languageJson['0005-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [0, 2, 8, 10, 14, 17, 20],
    imagePath: languageJson['0005-play-image-path'],
    imagePathAltText: languageJson['0005-play-text-image-alt'],
  },
  {
    id: '0006',
    title: languageJson['0006-play-title'],
    route: '0006',
    topic: languageJson['0006-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [1, 6, 9, 11, 15, 18, 21],
    imagePath: languageJson['0006-play-image-path'],
    imagePathAltText: languageJson['0006-play-text-image-alt'],
  },
  {
    id: '0007',
    title: languageJson['0007-play-title'],
    route: '0007',
    topic: languageJson['0007-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [1, 6, 9, 11, 15, 18, 21],
    imagePath: languageJson['0007-play-image-path'],
    imagePathAltText: languageJson['0007-play-text-image-alt'],
  },
  {
    id: '0008',
    title: languageJson['0008-play-title'],
    route: '0008',
    topic: languageJson['0008-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [1, 6, 9, 11, 15, 18, 21],
    imagePath: languageJson['0008-play-image-path'],
    imagePathAltText: languageJson['0008-play-text-image-alt'],
  },
  {
    id: '0009',
    title: languageJson['0009-play-title'],
    route: '0009',
    topic: languageJson['0009-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [1, 6, 9, 11, 15, 18, 21],
    imagePath: languageJson['0009-play-image-path'],
    imagePathAltText: languageJson['0009-play-text-image-alt'],
  },
  {
    id: '0010',
    title: languageJson['0010-play-title'],
    route: '0010',
    topic: languageJson['0010-play-topic'],
    gradeRangeId: [0, 1, 2, 3, 4, 5, 6],
    categoryIds: [1, 6, 9, 11, 15, 18, 21],
    imagePath: languageJson['0010-play-image-path'],
    imagePathAltText: languageJson['0010-play-text-image-alt'],
  },
  // {
  //   id: '0009',
  //   title: 'Science',
  //   route: '0009',
  //   topic: 'Science 0009',
  //   gradeRangeId: [1],
  //   categoryIds: [2],
  //   imagePath: languageJson['0002-play-image-path'],
  //   imagePathAltText: languageJson['0002-play-image-path'],
  // },
];

export default PlayManifest;
