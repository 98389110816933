import Color from 'color';
import { camelCase } from 'change-case';
import { faPencil, faPaintBrush, faSprayCan, faFillDrip } from '@fortawesome/pro-solid-svg-icons';
import { PabloConstants } from './PabloConstants';

/**
 * [Calculates the position of the triangle on the drawer]
 * @param  {[float]}  drawerWidth            [Width of open drawer]
 * @param  {[float]}  arrowHeight            [Arrow height]
 * @param  {[float]}  toolbarContainerTopPos [Top position of toolbar]
 * @param  {[float]}  selectedToolBottomPos  [Bottom position of selected toolbar icon]
 * @param  {[float]}  selectedToolHeight     [Height of selected toolbar icon]
 * @param  {[String]} direction              [Direction of arrow]
 * @return {[object]}                        [Object containing left and top positions of drawer arrow]
 */

export function calculateArrowPos(
  drawerWidth,
  arrowHeight,
  toolbarContainerTopPos,
  selectedToolBottomPos,
  selectedToolHeight,
  direction
) {
  // For the top position of the triangle take the bottom position of the selected tool element minus half the height of the selected tool elememt as well as half of the arrow's height
  // to point to approximately the middle of the selected tool (need to subtract the top position of the toolbarContainer element
  // (top of viewer window to top of toolbarContainer element) because the triangle is positioned relative to the entire viewer window)
  // Depending on the drawer set the left position (color, sticker) or right position (background) of the triangle to the width of the drawer (width may vary depending on the drawer)
  // and subtract 2 so that it covers the drawer's 2px border and add 6 which takes into account
  // the 6px of padding added to the left (right drawers) or right (left drawer)
  // and shifts the triangle to move to the new end of the drawer after the padding is applied
  // Padding was added because of ADA concern about outline not completely showing - KTWO-144
  const arrowPos = {};

  if (direction === 'right') {
    arrowPos.left = `${drawerWidth - 2 + 6}px`;
  } else {
    arrowPos.right = `${drawerWidth - 2 + 6}px`;
  }

  arrowPos.top = `${selectedToolBottomPos - toolbarContainerTopPos - selectedToolHeight / 2 - arrowHeight / 2}px`;

  return arrowPos;
}

export function setupColorSwashes() {
  // Set color to the appropriate drawer element
  const $colorSwashes = document.getElementsByClassName('color');

  [...$colorSwashes].forEach(child => {
    const colorSwash = child;
    const colorSwashColor = colorSwash.dataset.color;
    const colorSwashBorderColor = Color(colorSwashColor).darken(0.6);

    colorSwash.style.backgroundColor = colorSwashColor;
    colorSwash.style.border = `2px solid ${colorSwashBorderColor}`;
  });
}

/**
 * [Handler for situations where no animation is started]
 * @param  {[string]} element       [Selector for element to remove animate classes from]
 * @param  {[string]} animationName [Animation name]
 * @param  {Function} callback      [Callback that would normally be run after the animation has ended]
 */

export function handleNoAnimationEnd(element, animationName, callback) {
  const $node = document.querySelector(element);

  $node.classList.remove('animated', animationName);

  if (typeof callback === 'function') {
    // Type check to prevent "callback is not function" error
    callback();
  }
}

/**
 * [Will add the animation passed in as animationName to the passed in element with the callback run after the animation has concluded]
 * @param  {[string]} element       [Selector for element to add animation to]
 * @param  {[string]} animationName [Animation name]
 * @param  {Function} callback      [Callback to be run once animation has ended]
 */

export function animateCSS(element, animationName, callback) {
  const $node = document.querySelector(element);

  $node.classList.add('animated', animationName);

  const handleAnimationEnd = () => {
    $node.classList.remove('animated', animationName);
    $node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') {
      // Type check to prevent "callback is not function" error
      callback();
    }
  };

  $node.addEventListener('animationend', handleAnimationEnd);
}

/**
 * [Set the appropriate svg for the cursor depending on the active tool]
 * @param  {[string]} toolName    [Tool name]
 * @return {[string]}             [Custom cursor style]
 */

export function setCursorImage(toolName) {
  switch (toolName) {
    case 'marker':
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}markerCircle.svg) ${PabloConstants.CURSOR_OFFSETS.MARKER} ${PabloConstants.CURSOR_OFFSETS.MARKER}, pointer`;
    case 'pencil':
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}pencilCircle.svg) ${PabloConstants.CURSOR_OFFSETS.PENCIL} ${PabloConstants.CURSOR_OFFSETS.PENCIL}, pointer`;
    case 'spray':
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}sprayCircle.svg) ${PabloConstants.CURSOR_OFFSETS.SPRAY} ${PabloConstants.CURSOR_OFFSETS.SPRAY}, pointer`;
    case 'eraser':
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}eraserCircle.svg) ${PabloConstants.CURSOR_OFFSETS.ERASER} ${PabloConstants.CURSOR_OFFSETS.ERASER}, pointer`;
    case 'pattern':
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}stamp.svg) ${PabloConstants.CURSOR_OFFSETS.PATTERN_HORIZONTAL} ${PabloConstants.CURSOR_OFFSETS.PATTERN_VERTICAL}, pointer`;
    default:
      return `url(${PabloConstants.CURSORS_PATH_PREFIX}defaultCircle.svg) ${PabloConstants.CURSOR_OFFSETS.DEFAULT} ${PabloConstants.CURSOR_OFFSETS.DEFAULT}, pointer`;
  }
}

/**
 * [Return Font Awesome icon parameters for selected tool]
 * @param  {[string]} selectedTool [Selected tool name]
 * @return {[object]}              [Custom Font Awesome icon component parameters]
 */

export function determineSelectionWindowIconParams(selectedTool) {
  switch (selectedTool) {
    case 'pencil':
      return {
        className: 'selectionPencilIcon',
        icon: faPencil,
      };
    case 'marker':
      return {
        className: 'selectionBrushIcon',
        icon: faPaintBrush,
      };
    case 'spray':
      return {
        className: 'selectionSprayIcon',
        icon: faSprayCan,
      };
    case 'fill':
      return {
        className: 'selectionFillIcon',
        icon: faFillDrip,
      };
    default:
      return {};
  }
}

/**
 * [Return array of enabled color's hex codes as strings to satisfy Pablo stickerbook config]
 * @param  {[object]} enabledColors [Array of objects with each object containing the color's name and hex code]
 * @return {[object]}               [Array of strings with each string being the hex code of an enabled color - for Pablo stickerbook]
 */

export function createStickerbookEnabledColorConfig(enabledColors) {
  return enabledColors.map(enabledColor => {
    return enabledColor.hex;
  });
}

/**
 * [Return color name for given hex code]
 * @param  {[String]} hexCode [Color hex code]
 * @return {[String]}         [Name of color]
 */

export function getColorNameFromHexCode(hexCode) {
  return PabloConstants.ENABLED_COLORS.filter(enabledColor => {
    return enabledColor.hex === hexCode;
  })[0].name;
}

/**
 * [Return the correct key to get ARIA label from props for given type of item]
 * @param  {[String]} itemName       [Name of item: red, sky.svg, etc.]
 * @param  {[String]} type           [Type of item: color, background, etc.]
 * @param  {[String]} additionalText [Additional text to be added]
 * @return {[String]}                [Key to get ARIA label from props]
 */

export function createAriaLabelPropKey(itemName, type, additionalText = '') {
  if (type === 'background' || type === 'sticker') {
    return `${camelCase(itemName.replace('.svg', ''))}${additionalText}`;
  }

  if (type === 'color') {
    const colorName = getColorNameFromHexCode(itemName);

    return `${camelCase(colorName)}${additionalText}`;
  }

  return `${camelCase(itemName)}${additionalText}`;
}

/**
 * [Set focus on currently active toolbar button]
 * @param {[String]} toolbarSelection [Name of currently active tool]
 */

export function setToolbarFocus(toolbarSelection) {
  const $toolbarSelection = document.querySelector(`#${toolbarSelection}`);

  $toolbarSelection.focus();
}

export default {
  calculateArrowPos,
  setupColorSwashes,
  animateCSS,
  setCursorImage,
  determineSelectionWindowIconParams,
  createStickerbookEnabledColorConfig,
  createAriaLabelPropKey,
  handleNoAnimationEnd,
  setToolbarFocus,
};
