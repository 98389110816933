import React, { Component } from 'react';
import anime from 'animejs';
import './LostOtterComponent.scss';

class LostOtterComponent extends Component {
  constructor(props) {
    super(props);
    this.otter = document.getElementById('mrotter');
    this.openEyes = this.openEyes.bind(this);
    this.closeEyes = this.closeEyes.bind(this);
    this.blink = this.blink.bind(this);
    this.closeIn = this.closeIn.bind(this);
    this.shiftHands = this.shiftHands.bind(this);
    this.delay = Math.floor(Math.random() * Math.floor(300));
  }

  componentDidMount() {
    this.blink();
    // this.closeIn();
    this.shiftHands();
  }

  closeIn() {
    anime({
      targets: '#otter-complete-head',

      scale: '1',
      easing: 'linear',
      delay: Math.floor(Math.random() * Math.floor(1000) * 3),
      duration: Math.floor(Math.random() * Math.floor(1000) * 3),
      complete: () => {
        anime({
          targets: '#otter-complete-head',
          scale: '1.05',
          delay: Math.floor(Math.random() * Math.floor(1000)),
          duration: Math.floor(Math.random() * Math.floor(1000)),
          complete: () => {
            this.closeIn();
          },
        });
      },
    });
  }

  shiftHands() {
    anime({
      targets: ['#map', '#hands'],
      translateX: 2,
      direction: 'alternate',
      easing: 'linear',
      delay: Math.floor(Math.random() * Math.floor(5000)),

      complete: () => {
        anime({
          targets: ['#map', '#hands'],
          translateX: -2,
          easing: 'linear',
          complete: () => {
            this.shiftHands();
          },
        });
      },
    });
  }

  blink() {
    this.openEyes();
  }

  openEyes() {
    anime({
      targets: ['#left-eye-closed', '#right-eye-closed'],
      opacity: 0,
      delay: this.delay,
    });

    anime({
      targets: ['#left-eye-open', '#left-eye-open-highlight', '#right-eye-open', '#right-eye-open-highlight'],
      opacity: 1,
      delay: this.delay,
      complete: () => {
        this.closeEyes();
      },
    });
  }

  closeEyes() {
    const newDelay = Math.floor(Math.random() * Math.floor(3000));

    anime({
      targets: ['#left-eye-open', '#left-eye-open-highlight', '#right-eye-open', '#right-eye-open-highlight'],
      opacity: 0,
      delay: newDelay,
    });
    anime({
      targets: ['#left-eye-closed', '#right-eye-closed'],
      opacity: 1,
      delay: newDelay,
      complete: () => {
        this.openEyes();
      },
    });
  }

  render() {
    return (
      <div className="box">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="250"
          height="350"
          viewBox="0 0 66.146 92.604"
          id="svg1230"
        >
          <defs id="defs1224">
            <clipPath id="clipPath188-5">
              <path d="M237.477 451.137h89.052v-71.049h-89.052z" id="path186-1" />
            </clipPath>
            <clipPath id="clipPath174-0">
              <path d="M239.538 453.087h84.399v-73.999h-84.399z" id="path172-1" />
            </clipPath>
            <clipPath id="clipPath140-4">
              <path d="M108.19 450.137h97.049v-71.049H108.19z" id="path138-8" />
            </clipPath>
            <clipPath id="clipPath126-8">
              <path d="M108.19 453.087h97.049v-73.999H108.19z" id="path124-4" />
            </clipPath>
            <linearGradient
              xlinkHref="#linearGradient62"
              id="linearGradient1821"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(-.4262 0 0 .4262 26.53 204.13)"
              x1="-22.258"
              y1="71.516"
              x2="-42.657"
              y2="107.063"
            />
            <linearGradient
              x2="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="rotate(86.448 -27.571 253.662) scale(37.12075)"
              id="linearGradient62"
            >
              <stop offset="0" id="stop56" stopColor="#e78d56" />
              <stop offset="0.525" id="stop58" stopColor="#e78d56" />
              <stop offset="1" id="stop60" stopColor="#e16628" />
            </linearGradient>
            <linearGradient
              xlinkHref="#linearGradient62"
              id="linearGradient1819"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(40.198 204.13) scale(.4262)"
              x1="-22.258"
              y1="71.516"
              x2="-42.657"
              y2="107.063"
            />
            <linearGradient
              xlinkHref="#linearGradient62"
              id="linearGradient1815"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(39.918 203.965) scale(.4262)"
              x1="-17.765"
              y1="126.533"
              x2="-16.157"
              y2="100.173"
            />
            <linearGradient
              xlinkHref="#linearGradient2479"
              id="linearGradient1813"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(40.198 215.074) scale(.11276)"
              x1="-111.117"
              y1="96.68"
              x2="-106.066"
              y2="436.091"
            />
            <linearGradient
              id="linearGradient2479"
              gradientTransform="rotate(-90 305.561 127.063) scale(162.47253)"
              gradientUnits="userSpaceOnUse"
              x2="1"
            >
              <stop id="stop2475" offset="0" stopColor="#b83311" />
              <stop id="stop2477" offset="1" stopColor="#cf342e" />
            </linearGradient>
          </defs>
          <g id="layer1" transform="translate(0 -204.396)">
            <path
              id="path1623"
              d="M23.122 273.502l-.322 18.123h5.155l2.094-12.78s1.598-.186 2.422-.35c.824-.165 2.514-.645 2.514-.645l3.021 13.775h4.77l-.161-18.123z"
              fill="#f4a64b"
              strokeWidth="0.118"
            />
            <path
              id="path1627"
              d="M26.07 255.911c-11.626-.468-22.166 1.762-22.23 8.325-.055 5.655 10.518 7.545 19.282 9.266l1.345-6.554c-8.182.11-9.173-.773-12.582-2.518-3.408-1.746 12.09-2.688 12.09-2.688z"
              fill="#c8a66d"
              strokeWidth="0.113"
            />
            <path
              d="M47.632 290.849c0 1.145-.614 1.72-1.395 1.845-.874.14-1.992.219-3.386.219-2.64 0-5.334-.414-5.334-2.841s2.417-3.774 5.058-3.774c2.64 0 5.057 2.124 5.057 4.55z"
              id="path1631"
              fill="#c93426"
              strokeWidth="1.136"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="otter-complete-head">
              <path
                d="M32.385 222.935c-10.187 0-18.445 7.321-18.445 16.351.005 3.221.342 4.205 1.787 7.283 1.169 2.407 4.782 8.96 5.14 10.035h23.661l4.51-10.31h-.013a14.832 14.832 0 0 0 1.805-7.008c0-9.03-8.258-16.35-18.445-16.35z"
                id="path1565"
                fill="url(#linearGradient1813)"
                strokeWidth="0.113"
              />
              <g id="right-eye-open">
                <g id="g2038" transform="translate(73.244 220.74) scale(.31387)">
                  <g transform="translate(-22.943 -86.248) scale(1.86439)" id="g1577" strokeWidth="55.257">
                    <ellipse ry="6.016" rx="4.536" cy="69.049" cx="-39.396" id="ellipse1573" fill="#1a1a1a" />
                    <path
                      id="path1575"
                      d="M-39.396 63.033a4.536 6.016 0 0 0-4.535 6.016 4.536 6.016 0 0 0 .282 2.076 4.536 6.016 0 0 1 4.253-3.95 4.536 6.016 0 0 1 4.254 3.941 4.536 6.016 0 0 0 .282-2.067 4.536 6.016 0 0 0-4.536-6.016z"
                      fill="#5c3833"
                    />
                  </g>
                </g>
                <circle
                  r="0.802"
                  cy="235.795"
                  cx="42.429"
                  id="circle1994"
                  opacity="0.37"
                  fill="#fff"
                  strokeWidth="0.353"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M22.64 227.124c.02 2.206-2.382 2.98-8.539 5.8-2.67 1.221-4.994-1.643-4.994-3.867s2.38-4.027 5.316-4.027 8.195-.13 8.216 2.094z"
                id="path1569"
                fill="#b83311"
                strokeWidth="26.011"
              />
              <g id="lower-jaw" strokeLinecap="round">
                <ellipse
                  ry="5.236"
                  rx="5.799"
                  cy="249.274"
                  cx="33.313"
                  id="ellipse1567"
                  fill="url(#linearGradient1815)"
                  strokeWidth="0.801"
                />
                <ellipse
                  ry="1.611"
                  rx="2.336"
                  cy="248.324"
                  cx="33.313"
                  id="ellipse1571"
                  fill="#a22816"
                  strokeWidth="1.078"
                />
              </g>
              <path
                id="rect1990"
                opacity="0.37"
                fill="#e78d56"
                strokeWidth="0.353"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="0.922"
                d="M30.769 241.809h5.646v5.045h-5.646z"
              />
              <g id="left-eye-open">
                <g id="g2285">
                  <g id="g1589" transform="translate(46.753 193.738) scale(.58517)" strokeWidth="55.257">
                    <ellipse id="ellipse1585" cx="-39.396" cy="69.049" rx="4.536" ry="6.016" fill="#1a1a1a" />
                    <path
                      d="M-39.396 63.033a4.536 6.016 0 0 0-4.535 6.016 4.536 6.016 0 0 0 .282 2.076 4.536 6.016 0 0 1 4.253-3.95 4.536 6.016 0 0 1 4.254 3.941 4.536 6.016 0 0 0 .282-2.067 4.536 6.016 0 0 0-4.536-6.016z"
                      id="path1587"
                      fill="#5c3833"
                    />
                  </g>
                  <circle
                    r="0.802"
                    cy="235.929"
                    cx="24.221"
                    id="path1992"
                    opacity="0.37"
                    fill="#fff"
                    strokeWidth="0.353"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
              <path
                d="M26.903 237.035a8.266 6.004 0 0 0-8.266 6.005 8.266 6.004 0 0 0 8.266 6.004 8.266 6.004 0 0 0 6.409-2.22 8.266 6.004 0 0 0 .052.042v-7.65a8.266 6.004 0 0 0-.05.04 8.266 6.004 0 0 0-6.411-2.22z"
                id="path1597"
                fill="url(#linearGradient1819)"
                strokeWidth="1.138"
                strokeLinecap="round"
              />
              <path
                id="path1601"
                d="M39.824 237.035a8.266 6.004 0 0 1 8.266 6.005 8.266 6.004 0 0 1-8.266 6.004 8.266 6.004 0 0 1-6.409-2.22 8.266 6.004 0 0 1-.051.042v-7.65a8.266 6.004 0 0 1 .049.04 8.266 6.004 0 0 1 6.411-2.22z"
                fill="url(#linearGradient1821)"
                strokeWidth="1.138"
                strokeLinecap="round"
              />
              <ellipse
                id="ellipse1603"
                cx="33.512"
                cy="237.514"
                rx="7.168"
                ry="4.43"
                fill="#451818"
                strokeWidth="26.011"
              />
              <path
                id="path1607"
                d="M41.777 227.124c-.02 2.206 2.381 2.98 8.538 5.8 2.67 1.221 4.994-1.643 4.994-3.867s-2.38-4.027-5.316-4.027-8.195-.13-8.216 2.094z"
                fill="#b83311"
                strokeWidth="26.011"
              />
              <ellipse
                id="ellipse1609"
                cx="33.512"
                cy="236.789"
                rx="3.786"
                ry="2.577"
                fill="#974444"
                strokeWidth="0.674"
                strokeLinecap="round"
              />
              <path
                id="path1615"
                d="M21.517 242.607s-1.298-1.289-5.525-1.289c-4.226 0-5.524 1.289-5.524 1.289"
                fill="none"
                stroke="#451718"
                strokeWidth="0.992"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path1613"
                d="M23.106 244.619s-1.587-.91-5.67.185c-4.083 1.094-5.003 2.674-5.003 2.674"
                fill="none"
                stroke="#451718"
                strokeWidth="0.992"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M44.217 244.94s1.587-.908 5.67.186 5.003 2.675 5.003 2.675"
                id="path1617"
                fill="none"
                stroke="#451718"
                strokeWidth="0.992"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M45.806 242.93s1.298-1.29 5.525-1.29 5.525 1.29 5.525 1.29"
                id="path1619"
                fill="none"
                stroke="#451718"
                strokeWidth="0.992"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="g1645" transform="matrix(.14855 -.01827 -.02395 -.1133 6.924 275.134)">
                <g id="g1643">
                  <g id="g1641" clipPath="url(#clipPath126-8)" opacity="0.608">
                    <path id="path1639" d="M205.239 453.086H108.19v-73.999h97.049z" fill="#c6f9ff" />
                  </g>
                </g>
              </g>
              <g id="g1657" transform="matrix(.14855 -.01827 -.02395 -.1133 6.924 275.134)">
                <g id="g1655">
                  <g id="g1653" clipPath="url(#clipPath140-4)" opacity="0.608">
                    <g id="g1651" transform="translate(205.239 421.137)">
                      <path id="path1649" d="M0 0l-1 29h-16.35l-77.699-53.05-2-17.999h38.1z" fill="#ddfbff" />
                    </g>
                  </g>
                </g>
              </g>
              <path
                id="path1659"
                d="M12.146 221.82l14.416-1.772 1.773 8.384-14.417 1.773z"
                fill="none"
                stroke="#0cade2"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="g1669" transform="matrix(.14855 -.01827 -.02395 -.1133 6.924 275.134)">
                <g id="g1667">
                  <g id="g1665" clipPath="url(#clipPath174-0)" opacity="0.608">
                    <path id="path1663" d="M323.937 453.086h-84.398v-73.999h84.398z" fill="#c6f9ff" />
                  </g>
                </g>
              </g>
              <g id="g1681" transform="matrix(.14855 -.01827 -.02395 -.1133 6.924 275.134)">
                <g id="g1679">
                  <g id="g1677" clipPath="url(#clipPath188-5)" opacity="0.608">
                    <g id="g1675" transform="translate(326.53 422.137)">
                      <path id="path1673" d="M0 0l-.918 29H-15.92l-71.297-53.05-1.835-18h34.96z" fill="#ddfbff" />
                    </g>
                  </g>
                </g>
              </g>
              <path
                id="path1683"
                d="M31.658 219.421l12.537-1.541 1.772 8.384-12.537 1.542z"
                fill="none"
                stroke="#0cade2"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g transform="matrix(.14855 -.01827 -.02395 -.1133 27.293 223.538)" id="g1687">
                <path
                  id="path1685"
                  d="M0 0c.3.267.617.533.95.8C5.383 4.267 10.75 6 17.05 6c6.266 0 11.6-1.733 16-5.2.433-.334.833-.667 1.2-1"
                  fill="none"
                  stroke="#0cade2"
                  strokeWidth="10.65"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>

              <path
                id="left-eye-closed"
                d="M21.21 235.358s1.689-1.07 2.516-1.07c.828 0 2.449 1.07 2.449 1.07"
                fill="none"
                stroke="#1a1a1a"
                strokeWidth="1.006"
                strokeLinecap="round"
              />
              <path
                id="right-eye-closed"
                d="M40.655 235.358s1.688-1.07 2.515-1.07c.827 0 2.449 1.07 2.449 1.07"
                fill="none"
                stroke="#1a1a1a"
                strokeWidth="1.006"
                strokeLinecap="round"
              />
            </g>
            <path
              d="M39.341 255.927c11.625-.469 22.165 1.762 22.229 8.325.055 5.654-10.518 7.545-19.282 9.266l-1.345-6.554c8.183.11 9.173-.773 12.582-2.519 3.408-1.745-12.089-2.687-12.089-2.687z"
              id="path1625"
              fill="#c8a66d"
              strokeWidth="0.113"
            />
            <g id="map">
              <path
                d="M24.701 272.935s-3.248 3.155-7.445 4.418c-3.594 1.08-5.316.91-5.494 1.367-.124.319 2.152 1.595 4.206 2.257 4.993 1.611 7.33-.08 8.618-.725 1.289-.644-.11-5.22-.11-5.22z"
                id="path1621"
                fill="#861900"
                strokeWidth="0.113"
              />
              <path
                d="M17.484 256.448l5.638 18.585h19.493l5.135-18.913z"
                id="path1629"
                fill="#c8a66d"
                strokeWidth="0.119"
              />
              <path d="M26.07 255.911l6.477 14.23 6.794-14.214z" id="path1633" fill="#db7e35" strokeWidth="0.113" />
              <path
                d="M41.822 253.897c-1.525.032-4.31 1.473-5.28 2.014-.645.36-2.055 1.194-3.17 2.09-1.093-.858-2.421-1.646-3.043-1.992-1.15-.64-4.849-2.546-5.978-1.87-1.128.674-1.2 4.835-1.18 6.15.02 1.315.22 5.472 1.368 6.112 1.15.64 4.789-1.378 5.917-2.053.622-.372 1.963-1.223 3.04-2.132 1.054.871 2.32 1.675 2.919 2.033 1.128.675 4.768 2.693 5.917 2.053 1.149-.64 1.348-4.796 1.368-6.112.02-1.315-.052-5.475-1.18-6.15-.177-.106-.416-.149-.698-.143z"
                id="path1635"
                fill="#157d9e"
                strokeWidth="1.067"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.21 253.369v26.484l12.948 3.825 12.948-3.825 12.948 3.384L58 279.853v-26.484l-13.473 3.235-12.422-3.235-14.622 3.08z"
                id="path1691"
                fill="#3fa0ff"
                stroke="#f8ffff"
                strokeWidth="1.732"
                strokeLinejoin="round"
              />
              <path
                id="path1693"
                d="M6.21 252.466v26.485l12.948 3.825 12.948-3.825 12.948 3.384L58 278.95v-26.485l-13.473 3.236-12.422-3.236-14.622 3.08z"
                fill="#32d4e1"
                stroke="#f8ffff"
                strokeWidth="1.732"
                strokeLinejoin="round"
              />
              <path
                d="M7.056 253.533l.007 9.704c.305 4.432 1.51 9.675 6.071 9.97 4.561.294 4.414-5.297 8.975-3.237 4.562 2.06 8.24 5.296 11.183 4.12 2.942-1.178 5.59-6.18 10.005-4.268 4.414 1.913 8.092 6.327 9.71 4.709 1.619-1.619 4.856-5.15.883-8.387-3.972-3.237-4.414-6.474-3.678-8.092.736-1.619.502-2.904.502-2.904l-6.186 1.456-12.422-3.531-14.622 3.15z"
                id="path1695"
                fill="#fac871"
                strokeWidth="1.178"
              />
              <path
                d="M8.127 253.78l-1.07-.247-.004.986s1.661 4.596 6.007 8.506c1.103.993 2.026 1.598 2.951 1.872a3.48 3.48 0 0 0 2.558-.238c1.418-.726 2.623-1.28 3.992-2.102 1.026-.615 2.543-.925 4.447.135 1.903 1.06 3.707 2.756 4.38 3.851.197.32.246.911.03 1.733-.215.82-.67 1.793-1.189 2.695-1.037 1.803-3.227 2.325-3.227 2.325l4.87 2.159s-.993-1.456.154-3.45c.574-.997 1.107-2.093 1.398-3.203.291-1.11.367-2.312-.269-3.345-.99-1.61-2.933-3.348-5.138-4.577-2.206-1.228-5.237-2.866-7.332-1.609-1.752 1.051-2.346 3.177-3.061 3.543-.358.183-.565.23-1.025.095-.46-.136-1.175-.545-2.152-1.425-2.587-2.426-4.658-4.565-6.32-7.704z"
                id="path1697"
                style={{
                  lineHeight: 'normal',
                  fontVariantLigatures: 'normal',
                  fontVariantPosition: 'normal',
                  fontVariantCaps: 'normal',
                  fontVariantNumeric: 'normal',
                  fontVariantAlternates: 'normal',
                  fontFeatureSettings: 'normal',
                  textIndent: 0,
                  textAlign: 'start',
                  textDecorationLine: 'none',
                  textDecorationStyle: 'solid',
                  textDecorationColor: '#000',
                  textTransform: 'none',
                  textOrientation: 'mixed',
                  shapePadding: 0,
                  isolation: 'auto',
                  mixBlendMode: 'normal',
                }}
                color="#000"
                fontWeight="400"
                fontFamily="sans-serif"
                whitespace="normal"
                overflow="visible"
                fill="#32d4e1"
                strokeWidth="2.073"
              />
              <g id="g1703" transform="translate(132.125 212.233) scale(.32503)" strokeWidth="0.143">
                <path id="path1699" d="M-298.304 150.045l6.353-12.938 7.074 12.575z" fill="#b3b3b3" />
                <path d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" id="path1701" fill="#fff" />
              </g>
              <g transform="translate(116.947 221.944) scale(.25952)" id="g1709" strokeWidth="0.143">
                <path d="M-298.304 150.045l6.353-12.938 7.074 12.575z" id="path1705" fill="#b3b3b3" />
                <path id="path1707" d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" fill="#fff" />
              </g>
              <g transform="translate(106.189 225.504) scale(.22998)" id="g1715" strokeWidth="0.143">
                <path d="M-298.304 150.045l6.353-12.938 7.074 12.575z" id="path1711" fill="#b3b3b3" />
                <path id="path1713" d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" fill="#fff" />
              </g>
              <g id="g1721" transform="translate(116.159 223.14) scale(.25952)" strokeWidth="0.143">
                <path id="path1717" d="M-298.304 150.045l6.353-12.938 7.074 12.575z" fill="#b3b3b3" />
                <path d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" id="path1719" fill="#fff" />
              </g>
              <g transform="translate(113.796 223.53) scale(.25952)" id="g1727" strokeWidth="0.143">
                <path d="M-298.304 150.045l6.353-12.938 7.074 12.575z" id="path1723" fill="#b3b3b3" />
                <path id="path1725" d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" fill="#fff" />
              </g>
              <path
                d="M40.98 263.952s.11-.92 1.177-.515c1.066.404 1.25.478.33 1.14-.919.662.883.294.957 1.067.074.772-1.03.184-2.39.33-1.362.148-3.275 1.362-2.429.148.846-1.214 1.692-1.288 1.84-1.95.147-.662.515-.22.515-.22z"
                id="path1729"
                fill="#32d4e1"
                strokeWidth="0.113"
              />
              <g id="g1735" transform="translate(118.823 222.128) scale(.25952)" strokeWidth="0.143">
                <path id="path1731" d="M-298.304 150.045l6.353-12.938 7.074 12.575z" fill="#b3b3b3" />
                <path d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" id="path1733" fill="#fff" />
              </g>
              <g transform="translate(119.107 223.332) scale(.25952)" id="g1741" strokeWidth="0.143">
                <path d="M-298.304 150.045l6.353-12.938 7.074 12.575z" id="path1737" fill="#b3b3b3" />
                <path id="path1739" d="M-290.036 140.512l-1.915-3.405-1.7 3.462 2.792 1.176z" fill="#fff" />
              </g>
              <path
                d="M28.657 270.72s2.06-2.575 1.25-3.9c-.809-1.324-3.383-4.34-5.223-3.898-1.839.441-6.547 2.5-7.577 3.016-1.03.515-8.46-5.518-8.975-4.12-.515 1.398 1.25 8.902 4.12 10.226 2.648.515 5.444-3.458 6.253-3.458.809 0 2.869-.735 4.414.368 1.545 1.104 2.133 2.28 3.899 2.207 1.765-.073 1.839-.441 1.839-.441z"
                id="path1743"
                fill="#63b300"
                strokeWidth="0.113"
              />
              <g transform="translate(96.354 211.192) scale(.26625)" id="g1749">
                <path
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  id="path1745"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  id="ellipse1747"
                  cx="-172.616"
                  cy="205.861"
                  rx="4.829"
                  ry="3.625"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="g1755" transform="translate(95.113 212.893) scale(.26625)">
                <path
                  id="path1751"
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  ry="3.625"
                  rx="4.829"
                  cy="205.861"
                  cx="-172.616"
                  id="ellipse1753"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g transform="translate(87.958 223.511) scale(.2146)" id="g1761">
                <path
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  id="path1757"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  id="ellipse1759"
                  cx="-172.616"
                  cy="205.861"
                  rx="4.829"
                  ry="3.625"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="g1767" transform="translate(87.384 225.212) scale(.2146)">
                <path
                  id="path1763"
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  ry="3.625"
                  rx="4.829"
                  cy="205.861"
                  cx="-172.616"
                  id="ellipse1765"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="g1773" transform="translate(98.375 212.404) scale(.26625)">
                <path
                  id="path1769"
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  ry="3.625"
                  rx="4.829"
                  cy="205.861"
                  cx="-172.616"
                  id="ellipse1771"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g transform="translate(97.134 214.105) scale(.26625)" id="g1779">
                <path
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  id="path1775"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  id="ellipse1777"
                  cx="-172.616"
                  cy="205.861"
                  rx="4.829"
                  ry="3.625"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="g1785" transform="translate(89.98 224.723) scale(.2146)">
                <path
                  id="path1781"
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  ry="3.625"
                  rx="4.829"
                  cy="205.861"
                  cx="-172.616"
                  id="ellipse1783"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g transform="translate(89.405 226.423) scale(.2146)" id="g1791">
                <path
                  d="M-175.547 213.628l1.554-6.387h3.107l1.622 6.057z"
                  id="path1787"
                  fill="#c87137"
                  strokeWidth="0.265"
                />
                <ellipse
                  id="ellipse1789"
                  cx="-172.616"
                  cy="205.861"
                  rx="4.829"
                  ry="3.625"
                  fill="green"
                  strokeWidth="4.415"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M13.355 256.816c-.67 1.191.736 4.12 2.134 3.384 1.397-.736 6.253-3.31 8.018-2.796 1.766.515 7.651 4.93 8.608 3.678.956-1.25 3.899-3.972 1.177-5.959-2.722-1.986-6.18.368-8.166.368-1.986 0-5.646.874-7.568 1.394-1.922.52-3.533-1.261-4.203-.07z"
                id="path1793"
                fill="#d38d5f"
                strokeWidth="0.113"
              />
              <path
                d="M45.872 269.248s-3.31-.22-1.545-1.471c1.765-1.25-.66-4.945 1.73-4.937 2.39.008 2.978 2.289 2.095 3.392-.882 1.104-2.28 3.016-2.28 3.016z"
                id="path1795"
                fill="#d38d5f"
                strokeWidth="0.113"
              />
              <path
                d="M12.987 276.09c2.502 0 1.104.147 3.752-.736 2.649-.882 4.414-1.765 4.708-.22.295 1.545 4.488 2.06-.294 2.648-4.782.589-6.032 2.428-7.136.589-1.103-1.84-1.03-2.28-1.03-2.28z"
                id="path1797"
                opacity="0.26"
                fill="#006680"
                strokeWidth="0.113"
              />
              <path
                id="path1799"
                d="M37.117 274.987c1.25 0 .736-1.251 3.384-2.134 2.649-.883 4.782-.368 5.076 1.177.295 1.545 4.488 2.06-.294 2.649-4.782.588-6.032 2.427-7.136.588-1.103-1.839-2.28-2.28-1.03-2.28z"
                opacity="0.26"
                fill="#006680"
                strokeWidth="0.113"
              />
              <path
                d="M53.155 256.521c-1.471 1.766-2.649 1.619-1.986 2.649.662 1.03 1.103 4.782 2.28 3.384 1.177-1.398 2.796-.883 1.766-3.384-1.03-2.502-2.06-2.649-2.06-2.649z"
                id="path1801"
                fill="#25b7c8"
                strokeWidth="0.113"
              />
            </g>
            <g id="hands">
              <path
                d="M12.99 264.888c-.763-1.35-1.833-2.186-3.21-2.505-1.378-.319-2.656-.03-3.835.867-1.182.903-1.92 2.15-2.217 3.74-.299 1.585-.069 3.053.69 4.405.757 1.347 1.825 2.18 3.202 2.5 1.378.32 2.66.032 3.844-.862 1.177-.902 1.915-2.145 2.213-3.73.297-1.59.068-3.062-.686-4.415"
                id="hand-2"
                fill="#b73412"
                strokeWidth="0.15"
              />
              <path
                id="hand-1"
                d="M53.155 264.61c.764-1.351 1.834-2.186 3.212-2.505 1.377-.32 2.655-.03 3.834.867 1.181.903 1.92 2.15 2.217 3.739.299 1.585.068 3.053-.69 4.405-.758 1.348-1.825 2.181-3.203 2.5-1.377.32-2.658.032-3.843-.862-1.177-.901-1.915-2.145-2.213-3.73-.297-1.59-.068-3.061.686-4.414"
                fill="#b73412"
                strokeWidth="0.15"
              />
            </g>
            <path
              id="path1809"
              d="M18.796 290.555c0 1.145.614 1.72 1.395 1.845.874.14 1.992.219 3.386.219 2.64 0 5.334-.414 5.334-2.841 0-2.428-2.417-3.774-5.058-3.774-2.64 0-5.057 2.123-5.057 4.55z"
              fill="#c93426"
              strokeWidth="1.136"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default LostOtterComponent;
