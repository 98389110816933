import React from 'react';
import PropTypes from 'prop-types';
import FullScreen from 'react-full-screen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons';
import { withLocalize } from 'react-localize-redux';
import { Endpoint } from '../../constants';
import './MediaOverlay.scss';
import AnalyticsAPI from '../../Services/AnalyticsApi';

class OverlayImage extends React.Component {
  constructor(props) {
    super(props);

    const { credit, largeFilename, caption } = this.props;

    this.state = {
      credit,
      largeFilename,
      caption,
      isFull: false,
    };
  }

  componentDidMount() {
    AnalyticsAPI.manualWriteStat('/fundamentals/assets/image');
  }

  goFull = () => {
    this.setState(prevState => {
      return { isFull: !prevState.isFull };
    });
  };

  render() {
    const { credit, caption, largeFilename } = this.state;

    return (
      <div className="mx-auto  media-overlay-container">
        <figure className="media-overlay-figure">
          <FullScreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
            <div className="img-button-container">
              <img tabIndex="0" src={`${Endpoint.CDN_URL}${largeFilename}`} alt={caption} />
              <button className="expand" type="button" onClick={this.goFull} aria-label="Change View">
                <FontAwesomeIcon aria-hidden="true" size="3x" icon={faExpandAlt} />
                <span className="sr-only">Change View</span>
              </button>
            </div>
          </FullScreen>
          <figcaption>
            <p className="caption">{caption}</p>
            <p className="credit">{credit}</p>
          </figcaption>
        </figure>
      </div>
    );
  }
}

OverlayImage.propTypes = {
  credit: PropTypes.string,
  largeFilename: PropTypes.string,
  caption: PropTypes.string,
};

OverlayImage.defaultProps = {
  credit: null,
  largeFilename: null,
  caption: null,
};

export default withLocalize(OverlayImage);
