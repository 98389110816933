import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleLanguage, setActiveLanguage } from '../../Redux/actions/languageWidgetActions';
import './LanguageWidget.scss';

class LanguageWidget extends Component {
  constructor(props) {
    super(props);

    const { language } = this.props;

    this.toggleActiveLanguage = this.toggleActiveLanguage.bind(this);
    setActiveLanguage(language);
  }

  componentDidUpdate() {
    const { activeCountryCode, setActiveLanguage } = this.props;

    setActiveLanguage(activeCountryCode);
  }

  toggleActiveLanguage() {
    const { toggleLanguage } = this.props;

    toggleLanguage();
  }

  render() {
    return (
      <span className="hide">Language Widget</span>
      // <button className={btnClass} type="button" onClick={this.toggleActiveLanguage}>
      //   <span className>{activeCountryCode} - Language Widget</span>
      // </button>
    );
  }
}

const mapStateToProps = state => ({
  activeCountryCode: state.languageWidget.activeCountryCode,
});

export default withLocalize(
  connect(
    mapStateToProps,
    { toggleLanguage, setActiveLanguage }
  )(LanguageWidget)
);

LanguageWidget.propTypes = {
  activeCountryCode: PropTypes.string,
  setActiveLanguage: PropTypes.func.isRequired,
  language: PropTypes.string,
};

LanguageWidget.defaultProps = {
  language: null,
  activeCountryCode: null,
};
