import { Backgrounds, RoutePath, RouteHelpers } from '../../constants';
import { getLocalesByBucketIdAndRouteId } from '../../Utils/browseUtilities';
import store from '../../Redux/store';

export default function getBackgroundClassFromRoute(route) {
  let { pathname } = route;
  const { languageWidget } = store.getState();
  const homeRoute = languageWidget.translation['routes-home'];
  const { startLanguage } = languageWidget;

  if (pathname === homeRoute) {
    return Backgrounds.HOME.CLASS;
  }

  // Fix for KTWO-106
  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, -1);
  }

  // If the pathname is specified in the RoutePath object
  if (Object.values(RoutePath).indexOf(pathname) !== -1) {
    const urlEnd = pathname.split('/').pop();

    // Check if it is a browse page
    if (urlEnd !== RouteHelpers.BROWSE) {
      return Backgrounds[pathname.split('/')[1].toUpperCase()].CLASS;
    }

    return Backgrounds[`${pathname.split('/')[1]}_${urlEnd}`.toUpperCase()].CLASS;
  }

  const regex1 = new RegExp(`${RouteHelpers.PLAY}/[0-9]{4}`);

  // If it is a game page and the path is valid and it is applicable to the current locale
  // set game background; if not return the browse background
  if (pathname.match(regex1)) {
    const locales = getLocalesByBucketIdAndRouteId(0, pathname.split('/').pop());

    if (locales && locales.indexOf(startLanguage) !== -1) {
      return Backgrounds.PLAY_SOLO.CLASS;
    }

    return Backgrounds.PLAY_BROWSE.CLASS;
  }

  const regex3 = new RegExp(`${RouteHelpers.PLAY}/.+`);

  // If there is any other case where the path has some variation after play/ then return browse background
  if (pathname.match(regex3)) {
    return Backgrounds.PLAY_BROWSE.CLASS;
  }

  const regex2 = new RegExp(`${RouteHelpers.READ}/[0-9]{4}`);

  // If it is a book page and the path is valid and it is applicable to the current locale
  // set book background; if not return the browse background
  if (pathname.match(regex2)) {
    const locales = getLocalesByBucketIdAndRouteId(1, pathname.split('/').pop());

    if (locales && locales.indexOf(startLanguage) !== -1) {
      return Backgrounds.READ_SOLO.CLASS;
    }

    return Backgrounds.READ_BROWSE.CLASS;
  }

  const regex4 = new RegExp(`${RouteHelpers.READ}/.+`);

  // If there is any other case where the path has some variation after read/ then return browse background
  if (pathname.match(regex4)) {
    return Backgrounds.READ_BROWSE.CLASS;
  }

  return Backgrounds.ERROR.CLASS;
}
