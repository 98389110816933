import React from 'react';
import Helmet from 'react-helmet';
import { Howl } from 'howler';
import { gsap, Power2 } from 'gsap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { generateAudioPathPrefix, updateLiveReaderInfo } from '../../../Utils/audioUtilities';
import toggleAirdropAnimation from '../../../Redux/actions/animationActions';
import { togglePause } from '../../../Redux/actions/pauseManagerActions';
import Header from '../../../Components/Header/Header';
import { HomePageNav, Endpoint, Locales } from '../../../constants';
import ScreenSize from '../../../Components/ScreenSize/ScreenSize';
import Footer from '../../../Components/Footer/Footer';
import HomePageButtonLink from '../../../Components/HomePageButtonLink/HomePageButtonLink';
import LiveReader from '../../../Components/LiveReader/LiveReader';

import './HomeTemplate.scss';

class HomeTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mediaList: window.matchMedia('(min-width: 48em)'),
      tooSmall: window.innerWidth < 48,
      playNavVoiceover: true,
      // eslint-disable-next-line react/no-unused-state
      voiceoverName: '',
      // eslint-disable-next-line react/no-unused-state
      voiceoverHowl: null,
    };

    this.introduction = null;
    this.homeIconAnimation = gsap.timeline({ paused: true });

    const { mediaList } = this.state;

    this.destnation = null;
    this.screenSizeTest = this.screenSizeTest.bind(this);
    this.animateAllIcons = this.animateAllIcons.bind(this);
    this.animateAllIconsFin = this.animateAllIconsFin.bind(this);

    mediaList.addListener(this.screenSizeTest);

    this.voiceoverTimeout = null;
  }

  componentDidMount() {
    const { homePageScreenReaderGreeting } = this.props;

    this.introduction = setTimeout(() => {
      clearTimeout(this.introduction);
      updateLiveReaderInfo(homePageScreenReaderGreeting, 'interactive-text');
    }, 450);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.toggleAirdropAnimation();

    this.homeIconAnimation.add(
      gsap.to('.hp--ratio--button', 1, {
        scale: 0.75,
        ease: Power2.easeInOut,
      })
    );
    this.homeIconAnimation
      .add(
        gsap.to('.hp--ratio--button', 1, {
          marginTop: '-5000',
          ease: Power2.easeIn,
        })
      )
      .delay(0.5)
      .eventCallback('onComplete', this.animateAllIconsFin);
  }

  componentWillUnmount() {
    const { mediaList } = this.state;

    mediaList.removeListener(this.screenSizeTest);

    if (this.voiceoverTimeout) {
      clearTimeout(this.voiceoverTimeout);
    }
  }

  animateAllIconsFin() {
    const { history } = this.props;

    history.push(this.destination);
  }

  animateAllIcons(e) {
    e.persist();

    const $selector = e.currentTarget;
    const { playNavVoiceover } = this.state;
    const { startLanguage, shellNameCreate, shellNameExplore, shellNamePlay, shellNameRead } = this.props;

    let newVoiceoverName = e.target.dataset.name;

    document.querySelector(`button[data-name="${newVoiceoverName}"]`).focus();

    if (playNavVoiceover) {
      const audioFilePathPrefix = generateAudioPathPrefix(startLanguage, 'Shell');

      // Needed to add logic for Portuguese audio because of the way the localized
      // text is added to the data attributes and how the props are defined below
      // to pull the audio file name from localization
      if (startLanguage === Locales.PORTUGUESE_BRAZIL) {
        if (newVoiceoverName === shellNameCreate.toLowerCase()) {
          newVoiceoverName = 'create';
        } else if (newVoiceoverName === shellNameRead.toLowerCase()) {
          newVoiceoverName = 'read';
        } else if (newVoiceoverName === shellNameExplore.toLowerCase()) {
          newVoiceoverName = 'explore';
        } else if (newVoiceoverName === shellNamePlay.toLowerCase()) {
          newVoiceoverName = 'play';
        }
      }

      // eslint-disable-next-line react/destructuring-assignment
      const audioFileName = this.props[`${newVoiceoverName}Voiceover`];

      const VoiceoverHowl = new Howl({
        src: [`${audioFilePathPrefix}${audioFileName}`],
        html5: true,
        format: ['mp3'],
      });

      this.setState(
        {
          playNavVoiceover: false,
        },
        () => {
          VoiceoverHowl.play();
          this.destination = $selector.getAttribute('data-href');
          $selector.classList.remove('animated', 'bounceIn', 'slow');
          $selector.classList.add('animated', 'tada');

          setTimeout(() => {
            this.homeIconAnimation.play();
          }, 500);
        }
      );
    }
  }

  screenSizeTest(e) {
    // eslint-disable-next-line no-shadow
    const { togglePause } = this.props;

    if (!e.matches) {
      this.setState({ tooSmall: true }, () => {
        togglePause();
      });
    } else {
      this.setState({ tooSmall: false }, () => {
        togglePause();
      });
    }
  }

  render() {
    const { children, startLanguage } = this.props;
    const { tooSmall, playNavVoiceover } = this.state;

    return (
      <>
        <Helmet htmlAttributes={{ lang: startLanguage, class: startLanguage }} />
        <Header startLanguage={startLanguage} />
        <div id="template-name" className="HomeTemplate homeBackground">
          {children}
          <div className="sixteen-nine">
            <main className="content" id="main-content">
              <div role="navigation" className="hp--flex-wrapper">
                {HomePageNav.map(navItem => (
                  <HomePageButtonLink
                    animationStart={navItem.animationStart}
                    linkHref={navItem.linkHref}
                    imgSrc={`${Endpoint.CDN_URL}/fundamentals/app/learning-zone${navItem.imgSrc}`}
                    imgAltText={navItem.imgAltText}
                    label={navItem.label}
                    animateAllIcons={this.animateAllIcons}
                    key={navItem.label}
                    playNavVoiceover={playNavVoiceover}
                  />
                ))}
              </div>
            </main>
          </div>
        </div>
        <Footer />
        <LiveReader classHolder="home-page" />
        <ScreenSize isOpen={tooSmall} />
      </>
    );
  }
}

HomeTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  startLanguage: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  toggleAirdropAnimation: PropTypes.func.isRequired,
  togglePause: PropTypes.func.isRequired,
  audio: PropTypes.bool.isRequired,
  homePageScreenReaderGreeting: PropTypes.string.isRequired,
  shellNameCreate: PropTypes.string.isRequired,
  shellNameExplore: PropTypes.string.isRequired,
  shellNamePlay: PropTypes.string.isRequired,
  shellNameRead: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  audio: state.audioWidget.audio,
  shellNamePlay: state.languageWidget.translation['shell-name-play'],
  shellNameExplore: state.languageWidget.translation['shell-name-explore'],
  shellNameCreate: state.languageWidget.translation['shell-name-create'],
  shellNameRead: state.languageWidget.translation['shell-name-read'],
  createVoiceover: state.languageWidget.translation['shell-sounds-voice-over-create'],
  readVoiceover: state.languageWidget.translation['shell-sounds-voice-over-read'],
  exploreVoiceover: state.languageWidget.translation['shell-sounds-voice-over-explore'],
  playVoiceover: state.languageWidget.translation['shell-sounds-voice-over-play'],
  homePageScreenReaderGreeting: state.languageWidget.translation['shell-home-page-screen-reader-greeting'],
});

export default withRouter(
  connect(
    mapStateToProps,
    { toggleAirdropAnimation, togglePause }
  )(HomeTemplate)
);
