import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Endpoint } from '../../constants';
import './ScreenSize.scss';

class ScreenSize extends React.Component {
  constructor(props) {
    super(props);

    const { isOpen } = this.props;

    this.state = {
      modal: isOpen,
    };

    this.toggle = this.toggle.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen !== state.modal) {
      return {
        modal: props.isOpen,
      };
    }

    return null;
  }

  toggle() {
    this.setState({
      modal: false,
    });
  }

  render() {
    const { modal } = this.state;
    const { otterAlt } = this.props;

    return (
      <div>
        <Modal className="screen-size" isOpen={modal} toggle={this.toggle}>
          <ModalBody>
            <h1>
              <Translate id="shell-string-size-dialog-title" />
            </h1>

            <img
              alt={otterAlt}
              src={`${Endpoint.CDN_URL}/fundamentals/app/learning-zone/images/Shell/otter-size.png`}
            />
            <p>
              <Translate id="shell-string-size-dialog-copy" />
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  otterAlt: state.languageWidget.translation['shell-string-size-dialog-image-alt'],
});

ScreenSize.propTypes = {
  isOpen: PropTypes.bool,
  otterAlt: PropTypes.string.isRequired,
};

ScreenSize.defaultProps = {
  isOpen: false,
};

export default connect(mapStateToProps)(ScreenSize);
